import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";
import classNames from "classnames";
import DateFnsUtils from "@date-io/date-fns";
import { getValue } from "../../api/utils";
import { allowedWithDrawal, editWorkPlanStatuses, workPlanRevisions, workPlanRevisions2 } from "../../api/constants";

import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Create from "@material-ui/icons/Create";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AlarmIcon from "@material-ui/icons/Alarm";
import BackspaceIcon from "@material-ui/icons/Backspace";
import CheckIcon from "@material-ui/icons/Check";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import MailIcon from "@material-ui/icons/Mail";
import CloseIcon from "@material-ui/icons/Close";
import CommentIcon from "@material-ui/icons/Comment";
import BuildIcon from "@material-ui/icons/Build";
import EventIcon from "@material-ui/icons/Event";
import DeleteIcon from "@material-ui/icons/Delete";
import GetApp from "@material-ui/icons/GetApp";
import LockIcon from "@material-ui/icons/LockOutlined";
import Close from "@material-ui/icons/Close";
import GroupAddIcon from "@material-ui/icons/PersonAdd";
import PersonAddDisabled from "@material-ui/icons/PersonAddDisabled";
import Visibility from "@material-ui/icons/Visibility";

import DatePicker from "../common/DatePicker";
import WarningDialog from "../common/WarningDialog";
import Checkbox from "../common/Checkbox";
import { createSelector } from "../common/orm";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import RadioGroup from "../common/RadioGroup";
import TextField from "../common/TextField";
import Select from "../common/Select";

import IntroductionTab from "./tabs/Introduction";
import CollaboratorsProjectManagerTab from "./tabs/Collaborators_ProjectManager";
import CollaboratorsOrganizationTab from "./tabs/Collaborators_Organization";
import CollaboratorsPartnersTab from "./tabs/Collaborators_Partners";
import GeneralTab from "./tabs/General";
import NarrativeTab from "./tabs/Narrative";
import ActivityTab from "./tabs/Activity";
import BudgetPersonnelTab from "./tabs/Budget_Personnel";
import BudgetContractsTab from "./tabs/Budget_Contracts";
import BudgetExpendituresTab from "./tabs/Budget_Expenditures";
import BudgetAcquisitionTab from "./tabs/Budget_Acquisition";
import BudgetTravelTab from "./tabs/Budget_Travel";
import BudgetPrintingTab from "./tabs/Budget_Printing";
import BudgetOtherTab from "./tabs/Budget_Other";
import BudgetFundsTab from "./tabs/Budget_Funds";
import BudgetSummaryTab from "./tabs/Budget_Summary";
import AttachmentsTab from "./tabs/Attachments";
import AcquisitionParcelsTab from "./tabs/Acquisition_Parcels";
import AcquisitionFeeTab from "./tabs/Acquisition_Fee";
import AcquisitionConservationTab from "./tabs/Acquisition_Conservation";
import AcquisitionOtherTab from "./tabs/Acquisition_Other";
import AcquisitionRestorationTab from "./tabs/Acquisition_Restoration";
import Dissemination from "./tabs/Dissemination";
import FinalCommentsTab from "./tabs/Final_Comments";
import CommentsRevisionsTab from "./tabs/Comments_Revisions";
import ReviewTab from "./tabs/Review";
import ReportingTab from "./tabs/Reporting";
import AmendmentsTab from "./tabs/Amendments";
import FinalAbstractTab from "./tabs/Final_Abstract";

import {
    WorkPlanReview,
    WorkPlanComment,
    DraftWorkPlan,
    DraftProposalCollaborator,
    DraftProposalActivity,
    DraftProposalMilestone,
    DraftProposalPastProject,
    DraftProposalPersonnel,
    DraftProposalContract,
    DraftProposalExpenditure,
    DraftProposalAcquisition,
    DraftProposalTravel,
    DraftProposalPrinting,
    DraftProposalExpense,
    DraftProposalNonENRTFFund,
    DraftProposalAttachment,
    DraftProposalParcel,
    FinalProposalCollaborator,
    FinalProposalActivity,
    FinalProposalMilestone,
    FinalProposalPastProject,
    FinalProposalPersonnel,
    FinalProposalContract,
    FinalProposalExpenditure,
    FinalProposalAcquisition,
    FinalProposalTravel,
    FinalProposalPrinting,
    FinalProposalExpense,
    FinalProposalNonENRTFFund,
    FinalProposalAttachment,
    FinalProposalParcel,
    FinalWorkPlan,
    DraftWorkPlanView,
    FinalWorkPlanView,
    StatusUpdate,
    StatusUpdateActivity,
    AmendmentLog,
    ManagerRequest,
    CompletionRequest
} from "./models";

import { Proposal, ProposalView, ProposalDelegate } from "../proposal/models";
import { SystemSetting, ProposalTab } from "../home/models";

const getStatusUpdates = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        var wp = session.FinalWorkPlan.filter(f => f.proposal_record_id === id).toModelArray()[0];
        if (!wp) return null;
        return session.StatusUpdate.filter(x => x.work_plan === wp.id)
            .orderBy("id", "desc")
            .toModelArray()
            .map(su => ({
                _activities: su.status_update_activity
                    .all()
                    .orderBy("id")
                    .toRefArray(),
                ...su.ref
            }));
    }
);

const getManagerRequests = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ManagerRequest.filter(x => x.proposal_id === id).orderBy("id", "desc").toModelArray();
    }
);

const getCompletionRequests = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.CompletionRequest.filter(x => x.proposal_id === id).orderBy("id", "desc").toModelArray();
    }
);

const getAmendments = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        var wp = session.FinalWorkPlan.filter(f => f.proposal_record_id === id).toModelArray()[0];
        if (!wp) return null;
        return session.AmendmentLog.filter(x => x.work_plan === wp.id).orderBy("id", "desc").toModelArray();
    }
);

const getStatusUpdateActivities = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.StatusUpdateActivity.all()
            .orderBy("id")
            .toRefArray();
    }
);

const getProposal = Proposal.selectByUrlId(proposal => ({
    formData: proposal.ref,
    ...proposal.ref,
    org_data: proposal.organization,
    agent_data: proposal.fiscal_agent
}));

const getFundingArrays = createSelector(
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, isDraft) => {
        if (isDraft) {
            return {
                ProposalPersonnel: session.DraftProposalPersonnel.all().toRefArray(),
                ProposalContract: session.DraftProposalContract.all().toRefArray(),
                ProposalExpenditure: session.DraftProposalExpenditure.all().toRefArray(),
                ProposalAcquisition: session.DraftProposalAcquisition.all().toRefArray(),
                ProposalTravel: session.DraftProposalTravel.all().toRefArray(),
                ProposalPrinting: session.DraftProposalPrinting.all().toRefArray(),
                ProposalExpense: session.DraftProposalExpense.all().toRefArray(),
                ProposalNonENRTFFund: session.DraftProposalNonENRTFFund.all().toRefArray()
            };
        } else {
            return {
                ProposalPersonnel: session.FinalProposalPersonnel.all().toRefArray(),
                ProposalContract: session.FinalProposalContract.all().toRefArray(),
                ProposalExpenditure: session.FinalProposalExpenditure.all().toRefArray(),
                ProposalAcquisition: session.FinalProposalAcquisition.all().toRefArray(),
                ProposalTravel: session.FinalProposalTravel.all().toRefArray(),
                ProposalPrinting: session.FinalProposalPrinting.all().toRefArray(),
                ProposalExpense: session.FinalProposalExpense.all().toRefArray(),
                ProposalNonENRTFFund: session.FinalProposalNonENRTFFund.all().toRefArray()
            };
        }
    }
);

const getPlan = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftWorkPlan.all()
                .filter(x => x.proposal_record_id === id)
                .toModelArray()
                .map(plan => ({
                    formData: plan.ref,
                    ...plan.ref,
                    org_data: plan.organization,
                    agent_data: plan.fiscal_agent
                }))[0];
        } else {
            return session.FinalWorkPlan.all()
                .filter(x => x.proposal_record_id === id)
                .toModelArray()
                .map(plan => ({
                    formData: plan.ref,
                    ...plan.ref,
                    org_data: plan.organization,
                    agent_data: plan.fiscal_agent
                }))[0];
        }
    }
);
const getComments = WorkPlanComment.selectAll();
const getRFPs = SystemSetting.selectAll();
const getHelpMessages = ProposalTab.selectMessage();
const styles = theme => ({
    listItem: {
        borderBottom: "1px solid #666666",
        "&:focus": {
            backgroundColor: "rgba(0, 0, 0, 0.08) !important"
        }
    },
    stepRoot: {
        backgroundColor: "#eeeeee",
        marginBottom: theme.spacing.unit * 2,
        padding: theme.spacing.unit,
        paddingLeft: 0,
        paddingRight: 0,
        overflowX: "auto"
    },
    pointer: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        borderBottom: "8px solid transparent",
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        "&:hover": {
            borderBottom: "8px solid lightgray"
        }
    },
    pointerr: {
        borderBottom: "8px solid lightgray",
        marginBottom: "-8px !important"
    },
    pointer2: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        borderBottom: "8px solid " + theme.palette.primary.main
    },
    activePoint: {
        borderBottom: "8px solid #4f98ce",
        marginBottom: "-8px !important"
    },
    btnroot: {
        padding: 0,
        margin: 0
    },
    stepColor: {
        "& svg": {
            color: "#fff" // + " !important",
        },
        "& svg text": {
            fill: "#000" // + " !important",
        }
    },
    lessMargin: {
        "& span": {
            marginTop: "8px !important",
            color: "#000"
        }
    },
    stepperWidth: {
        marginLeft: -20,
        marginRight: -20,
        maxWidth: "calc(100% + 40px)",
        flexBasis: "unset",
        width: "calc(100% + 40px)",
        padding: "0 !important"
    },
    list: {
        "& ul": {
            padding: 0
        }
    },
    topListText: {
        textAlign: "center",
        padding: 0
    },
    hideIcon: {
        display: "none"
    },
    subStep: {
        marginLeft: 16,
        marginTop: -16,
        padding: 8,
        backgroundColor: "#00406e",
        alignItems: "flex-end"
    },
    subStepContainer: {
        marginLeft: -30,
        marginRight: -30,
        backgroundColor: "#00406e"
    },
    whiteLabel: {
        color: "#fff !important"
    },
    disabledStep: {
        borderBottom: "8px solid transparent",
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important"
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 24
    },
    titleContainer: {
        backgroundColor: theme.palette.primary.main,
        textAlign: "center"
    },
    questionTitle: {
        marginTop: ".6em",
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: "1.15rem",
        lineHeight: 1.2
    },
    greyButton: {
        backgroundColor: "#808080",
        color: "#fff"
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
    nooverflow: {
        overflow: "hidden"
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    }
});
function getSteps(isDraft, isAdmin, isUser, hideComments, proposal_status, is_dnr, is_member) {
    // These steps are always present for every user
    var return_steps = [
        "Introduction",
        "Project Collaborators",
        "General Information",
        "Narrative",
        "Activities and Milestones",
        "Budget",
        "Attachments",
        "Acquisition and Restoration",
        "Dissemination"
    ];

    if (isAdmin) return_steps.push("Comments and Revisions");

    if (((isUser && !hideComments) || (isUser && !isDraft)) && !is_dnr) return_steps.push("Comments and Revisions");

    if ((proposal_status === "Final Work Plan Approved" || proposal_status === "Final Abstract Due" || proposal_status === "Final Abstract Submitted" || proposal_status === "Final Abstract Revisions Needed"  || proposal_status === "Project Completed")) { 
        return_steps.push("Reporting");
    }

    if (isUser && !is_dnr) return_steps.push("Review / Submit");

    return return_steps;
}

function getCollaboratorSteps() {
    return ["Project Manager Info", "Organization Info", "Project Partner Info"];
}
function getBudgetSteps() {
    return [
        "Personnel",
        "Professional / Technical Contracts",
        "Capital, Equipment, Tools, and Supplies",
        "Acquisition",
        "Travel and Conferences",
        "Printing and Publication",
        "Other",
        "Non-ENRTF Funds Contributed",
        "Summary"
    ];
}
function getMemberBugetSteps() {
    return ["Summary"];
}
function getParcelSteps() {
    return ["Parcel List", "Fee Acquisition", "Conservation Easement Acquisition", "Easement Acquisition (Other)", "Restoration"];
}
function getReportingSteps(status) {
    var return_steps = ["Status Updates", "Amendments"];
    
    if (status === "Final Abstract Due" || status === "Final Abstract Submitted" || status === "Final Abstract Revisions Needed" || status === "Final Abstract Revisions Submitted" || status === "Project Completed") {
        return_steps.push("Final Abstract")
    }
    return return_steps;
}

const getDelegations = createSelector(schema => {
    return schema.ProposalDelegate.all().toRefArray();
});

class List extends Component {
    constructor(props) {
        super(props);
        this.appContainer = React.createRef();
        this.targetRef = React.createRef();
    }
    state = {
        acceptPlanDialog: false,
        unsavedFields: false,
        snackbarOpen: false,
        adminSaving: false,
        anchorEl: null,
        delegateDialog: false,
        formkey: null,
        deleteOptions: [],
        addStaffComment: false,
        reqeuestWidrawal: false,
        changedStep: false,
        reachedNotReady: false,
        loaded: false,
        reviewLoaded: false,
        firstLoaded: false,
        keepState: false,
        statusUpdateChangeDate: false,
        abstractChangeDate: false,
        denyContents: null,
        loadingAmendment: false,
        amendmentStartOpen: false,
        amendmentEndOpen: false,
        denyProjectManagerState: false,
        denyCompletionManagerState: false,
        isDraft: this.props.match.path === "/draft/workplan/:id",
        ormPropPrefix: this.props.match.path === "/draft/workplan/:id" ? "Draft" : "Final"
    };
    abstractDueDate = values => {
        const { proposal, ormProposalUpdate, history } = this.props;

        ormProposalUpdate({
            id: proposal.id,
            abstract_due_date: values.due_date.substring(0, 10)
        });

        this.setState({ finalUpdateDue: false });
        setTimeout(function() {
            history.push("/dashboard/");
        }, 1000);
    };
    dueDate = values => {
        const { plan, proposal, ormProposalUpdate, ormProposalViewUpdateLocalOnly } = this.props;
        const { ormPropPrefix } = this.state;
        var status = "";

        if (
            proposal.status === "Draft Work Plan Submitted" ||
            proposal.status === "Draft Work Plan Revisions Submitted" ||
            proposal.status === "Draft Work Plan Accepted"
        ) {
            status = "Draft Work Plan Revisions Needed";
        } else if (
            proposal.status === "Final Work Plan Submitted" ||
            proposal.status === "Final Work Plan Revisions Submitted" ||
            proposal.status === "Final Work Plan Accepted"
        ) {
            status = "Final Work Plan Revisions Needed";
        } else {
            //shouldn't reach here
        }

        ormProposalUpdate({
            id: proposal.id,
            status,
            due_date: values.due_date.substring(0, 10)
        });
        ormProposalViewUpdateLocalOnly({
            id: proposal.id,
            status,
            due_date: values.due_date.substring(0, 10)
        });

        this.props[`orm${ormPropPrefix}WorkPlanUpdate`]({
            id: plan.id,
            status_date: this.getDateTimeStamp()
        });
        this.props[`orm${ormPropPrefix}WorkPlanViewUpdateLocalOnly`]({
            id: plan.id,
            status_date: this.getDateTimeStamp()
        });

        this.setState({ revisionOpen: false });
        this.actionMenuClose();
        this.handleStep(8, false, 0);
    };
    validateDueDate = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            due_date: isRequired(values.due_date)
        };

        return valObj;
    };
    validateDeny = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            decline_reason: isRequired(values.decline_reason)
        };

        return valObj;
    };
    requestWithDrawal = values => {
        const { proposal, ormProposalUpdate, ormProposalViewUpdateLocalOnly } = this.props;
        ormProposalUpdate({
            id: proposal.id,
            prev_status: proposal.secondary_status,
            secondary_status: "Withdrawal Requested",
            secondary_date: this.getDateTimeStamp(),
            withdrawal_reason: values.withdrawal_reason
        });
        ormProposalViewUpdateLocalOnly({
            id: proposal.id,
            prev_status: proposal.secondary_status,
            secondary_status: "Withdrawal Requested",
            secondary_date: this.getDateTimeStamp(),
            withdrawal_reason: values.withdrawal_reason
        });
        this.setState({ reqeuestWidrawal: false });
        this.actionMenuClose();
    };
    addStaffComment = values => {
        const { authState, proposal, ormWorkPlanCommentCreate } = this.props;
        ormWorkPlanCommentCreate({
            proposal_id: proposal.id,
            user_id: authState.user.id,
            ...values
        });
        this.setState({ addStaffComment: false });
        this.actionMenuClose();
    };
    validateComment = values => {
        const isRequired = val => {
            return !val ? "Reason Required" : null;
        };
        var valObj = {
            comment: isRequired(values.comment)
        };

        return valObj;
    };
    acceptPlan = phase => {
        const { plan, proposal, ormProposalUpdate, ormProposalViewUpdateLocalOnly } = this.props;
        const { ormPropPrefix } = this.state;
        if (phase === "Draft") {
            ormProposalUpdate({
                id: proposal.id,
                status: "Draft Work Plan Accepted"
            });
            ormProposalViewUpdateLocalOnly({
                id: proposal.id,
                status: "Draft Work Plan Accepted"
            });
            this.props[`orm${ormPropPrefix}WorkPlanUpdate`]({
                id: plan.id,
                status_date: this.getDateTimeStamp()
            });
        } else {
            ormProposalUpdate({
                id: proposal.id,
                status: "Final Work Plan Accepted"
            });
            ormProposalViewUpdateLocalOnly({
                id: proposal.id,
                status: "Final Work Plan Accepted"
            });
            this.props[`orm${ormPropPrefix}WorkPlanUpdate`]({
                id: plan.id,
                status_date: this.getDateTimeStamp()
            });
        }
        this.setState({ acceptPlanDialog: false });
    };
    getDateTimeStamp = () => {
        const date = new Date();
        const dateFns = new DateFnsUtils();
        return dateFns.format(date, "yyyy-MM-dd HH:mm:ss.SSSSSS");
    };
    handleUnsavedFields = unsavedFields => {
        this.setState({ unsavedFields });
    };
    isLastStep(step) {
        const { authState, proposal } = this.props;
        const { isDraft, keepState } = this.state;
        const isAdmin = authState && authState.user && authState.user.role === "Staff";
        const isUser = authState && authState.user && authState.user.role === "User";
        const isDNR = authState && authState.user && authState.user.is_read_only;
        const hideComments = !proposal.status.includes("Due") && !proposal.status.includes("Needed") && !keepState;

        var steps = getSteps(isDraft, isAdmin, isUser, hideComments, proposal.status, isDNR);

        return step === steps.length - 1;
    }
    isFirstStep(step) {
        return step === 0;
    }
    // This is modified from the Proposal calcFundingTotal as the Proposals pk is used still so can't filter until
    // here when id of draft or final work plan is accessible.
    calcFundingTotal = () => {
        const { funding, plan } = this.props;
        var id = plan.id;
        var full_list = [
            ...funding.ProposalPersonnel.filter(x => x.proposal === id),
            ...funding.ProposalContract.filter(x => x.proposal === id),
            ...funding.ProposalExpenditure.filter(x => x.proposal === id),
            ...funding.ProposalAcquisition.filter(x => x.proposal === id),
            ...funding.ProposalTravel.filter(x => x.proposal === id),
            ...funding.ProposalPrinting.filter(x => x.proposal === id),
            ...funding.ProposalExpense.filter(x => x.proposal === id)
        ];
        var tempFundingTotal = 0.0;
        full_list.map(x => (tempFundingTotal += parseInt(x.amount)));
        this.props[`orm${this.state.ormPropPrefix}WorkPlanUpdate`]({
            id: id,
            funding_total: tempFundingTotal
        });
    };
    // This is called when the user clicks the save button at the bottom of the page.
    // Just determine what the next tab should be and pass to handle step.
    handleNext = () => {
        const { history, plan } = this.props;
        let { activeStep, activeSubStep } = plan;
        const Restoration = getValue(plan, "land_rights").indexOf("Restoration") > -1;
        const Conservation = getValue(plan, "land_rights").indexOf("Conservation Easement Acquisition") > -1;
        const Easement = getValue(plan, "land_rights").indexOf("Easement (Other) Acquisition") > -1;
        const Fee = getValue(plan, "land_rights").indexOf("Fee Acquisition") > -1;
        if (!activeStep) activeStep = 0;
        // They've saved the last step - reset the numbering system and go to the dashboard.
        if (this.isLastStep(activeStep)) {
            activeStep = 0;
            activeSubStep = 0;
            history.push("/dashboard");
        } else {
            // These are the steps without any substeps - just increment as normal
            if (
                activeStep === 0 ||
                activeStep === 2 ||
                activeStep === 3 ||
                activeStep === 4 ||
                activeStep === 6 ||
                activeStep === 8 ||
                activeStep === 9 ||
                activeStep === 10
            ) {
                if (activeStep === 6 && !Restoration && !Conservation && !Easement && !Fee) {
                    // Skip Acquistion and Restoration if nothing checked
                    activeStep = (activeStep || 0) + 2;
                } else {
                    activeStep = (activeStep || 0) + 1;
                }
                activeSubStep = 0;
            } else {
                if (activeStep === 1) {
                    // The step is over, increment the main step
                    if (getCollaboratorSteps().length - 1 === activeSubStep) {
                        activeStep = (activeStep || 0) + 1;
                        activeSubStep = 0;
                    } else {
                        activeSubStep = (activeSubStep || 0) + 1;
                    }
                } else if (activeStep === 5) {
                    // The step is over, increment the main step
                    if (getBudgetSteps().length - 1 === activeSubStep) {
                        activeStep = (activeStep || 0) + 1;
                        activeSubStep = 0;
                    } else {
                        activeSubStep = (activeSubStep || 0) + 1;
                        if (activeSubStep === 3 && !Fee && !Conservation && !Easement) {
                            activeSubStep = (activeSubStep || 0) + 1;
                        }
                    }
                } else if (activeStep === 7) {
                    // The step is over, increment the main step
                    if (getParcelSteps().length - 1 === activeSubStep) {
                        activeStep = (activeStep || 0) + 1;
                        activeSubStep = 0;
                    } else {
                        activeSubStep = (activeSubStep || 0) + 1;
                        if (activeSubStep === 1 && !Fee) activeSubStep = (activeSubStep || 0) + 1;
                        if (activeSubStep === 2 && !Conservation) activeSubStep = (activeSubStep || 0) + 1;
                        if (activeSubStep === 3 && !Easement) activeSubStep = (activeSubStep || 0) + 1;
                        if (activeSubStep === 4 && !Restoration) {
                            activeStep = (activeStep || 0) + 1;
                            activeSubStep = 0;
                        }
                    }
                }
            }
        }
        this.handleStep(activeStep, false, activeSubStep);
    };
    handlePrev = () => {
        const { history, plan } = this.props;
        let { activeStep, activeSubStep } = plan;
        const Restoration = getValue(plan, "land_rights").indexOf("Restoration") > -1;
        const Conservation = getValue(plan, "land_rights").indexOf("Conservation Easement Acquisition") > -1;
        const Easement = getValue(plan, "land_rights").indexOf("Easement (Other) Acquisition") > -1;
        const Fee = getValue(plan, "land_rights").indexOf("Fee Acquisition") > -1;
        if (!activeStep || activeStep <= 0) {
            activeStep = 0;
            activeSubStep = 0;
            history.push("/dashboard");
        }
        if (activeStep === 1) {
            if (activeSubStep === 0) {
                activeStep = (activeStep || 0) - 1;
                activeSubStep = 0;
            } else {
                activeSubStep = (activeSubStep || 0) - 1;
            }
        }
        if (activeStep === 2) {
            activeStep = (activeStep || 0) - 1;
            activeSubStep = getCollaboratorSteps().length - 1;
        }
        if (activeStep === 3) {
            activeStep = (activeStep || 0) - 1;
            activeSubStep = 0;
        }
        if (activeStep === 4) {
            activeStep = (activeStep || 0) - 1;
            activeSubStep = 0;
        }
        if (activeStep === 5) {
            if (activeSubStep === 0) {
                activeStep = (activeStep || 0) - 1;
                activeSubStep = 0;
            } else if (activeSubStep === 4 && !Conservation && !Easement && !Fee) {
                activeSubStep = (activeSubStep || 0) - 2;
            } else {
                activeSubStep = (activeSubStep || 0) - 1;
            }
        }
        if (activeStep === 6) {
            activeStep = (activeStep || 0) - 1;
            activeSubStep = getBudgetSteps().length - 1;
        }
        if (activeStep === 7) {
            if (activeSubStep === 0) {
                activeStep = (activeStep || 0) - 1;
                activeSubStep = 0;
            } else {
                activeSubStep = (activeSubStep || 0) - 1;
                if (activeSubStep === 3 && !Easement) activeSubStep = (activeSubStep || 0) - 1;
                if (activeSubStep === 2 && !Conservation) activeSubStep = (activeSubStep || 0) - 1;
                if (activeSubStep === 1 && !Fee) activeSubStep = (activeSubStep || 0) - 1;
            }
        }
        if (activeStep === 8) {
            if (!Restoration && !Conservation && !Easement && !Fee) {
                // Skip Acquistion and Restoration if nothing checked
                activeStep = (activeStep || 0) - 2;
                activeSubStep = 0;
            } else {
                activeStep = (activeStep || 0) - 1;
                activeSubStep = getParcelSteps().length - 1;
                if (activeSubStep === 4 && !Restoration) {
                    activeSubStep = (activeSubStep || 0) - 1;
                }
                if (activeSubStep === 3 && !Easement) activeSubStep = (activeSubStep || 0) - 1;
                if (activeSubStep === 2 && !Conservation) activeSubStep = (activeSubStep || 0) - 1;
                if (activeSubStep === 1 && !Fee) activeSubStep = (activeSubStep || 0) - 1;
            }
        }
        if (activeStep === 9) {
            activeStep = (activeStep || 0) - 1;
            activeStep = 8;
        }
        if (activeStep === 10) {
            activeStep = (activeStep || 0) - 1;
            activeStep = 9;
        }
        this.handleStep(activeStep, false, activeSubStep);
    };
    // This is called either from clicking on the stepper (fromStepper) or from handleNext.
    // If it is fromStepper it will perform a submitForm on the form of the active tab.
    // If it is from handleNext then the steps are just updated.
    // Note that handleNext is called from the form submission but only if the form isn't submitted from handleStep (!Number.isInteger(fromStepper))
    handleStep = (step, fromStepper, subStep = 0) => {
        const { id } = this.props.plan;
        const { ormPropPrefix } = this.state;
        this.handleUnsavedFields(false);
        // Hacky method to save form when using stepper
        if (fromStepper) {
            // Apparently just having a single "activeForm" works because only one stepper detail can be active at once
            if (this.activeForm) {
                const f = this;
                setTimeout(function() {
                    // The state is not set right away...
                    // If there is a series of forms save all of the forms (Activity Tab + others?)
                    if (Array.isArray(f.activeForm.form)) {
                        f.activeForm.form.forEach(function(fo) {
                            fo.submitForm();
                        });
                        // Then save the next steps onto the proposal because those forms above are not the proposal.
                        f.props[`orm${ormPropPrefix}WorkPlanUpdateLocalOnly`]({
                            id: id,
                            activeStep: step,
                            activeSubStep: subStep
                        });
                    } else if (f.activeForm.form) {
                        // Regular form submission - let the form save the proposal
                        f.activeForm.form.submitForm({ step: step, subStep: subStep });
                    } else {
                        // The parcel list has no form - update the step here
                        f.props[`orm${ormPropPrefix}WorkPlanUpdateLocalOnly`]({
                            id: id,
                            activeStep: step,
                            activeSubStep: subStep
                        });
                    }
                }, 50);
            } else {
                this.props[`orm${ormPropPrefix}WorkPlanUpdateLocalOnly`]({
                    id: id,
                    activeStep: step,
                    activeSubStep: subStep
                });
            }
        } else {
            this.props[`orm${ormPropPrefix}WorkPlanUpdateLocalOnly`]({
                id: id,
                activeStep: step,
                activeSubStep: subStep
            });
        }
        if (this.props.proposal === "Draft In Progress") this.setState({ snackbarOpen: true });
    };
    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    createStatusUpdate = id => {
        const { authState, proposal } = this.props;
        const { ormPropPrefix, isDraft, keepState } = this.state;
        const f = this;
        const isAdmin = authState && authState.user && authState.user.role === "Staff";
        const isUser = authState && authState.user && authState.user.role === "User";
        const isDNR = authState && authState.user && authState.user.is_read_only;
        const hideComments = !proposal.status.includes("Due") && !proposal.status.includes("Needed") && !keepState;
        var steps = getSteps(isDraft, isAdmin, isUser, hideComments, proposal.status, isDNR);

        fetch("statusupdate/create", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify({
                work_plan: id
            })
        }).then(result => {
            f.props[`orm${ormPropPrefix}WorkPlanUpdateLocalOnly`]({
                id: id,
                activeStep: steps.indexOf("Reporting"),
                activeSubStep: 0
            });
            window.location.reload();
        });
    };

    componentDidMount() {
        const {
            plan,
            proposal,
            ormWorkPlanReviewLoadDetailChildFilterMany,
            ormWorkPlanCommentReloadOverWrite,
            ormProposalLoadDetail,
            ormDraftWorkPlanLoadDetailChild,
            ormFinalWorkPlanLoadDetailChild,
            ormDraftProposalCollaboratorLoadDetailChild,
            ormDraftProposalActivityLoadDetailChild,
            ormDraftProposalMilestoneLoadDetailChild,
            ormDraftProposalPastProjectLoadDetailChild,
            ormDraftProposalPersonnelLoadDetailChild,
            ormDraftProposalContractLoadDetailChild,
            ormDraftProposalExpenditureLoadDetailChild,
            ormDraftProposalAcquisitionLoadDetailChild,
            ormDraftProposalTravelLoadDetailChild,
            ormDraftProposalPrintingLoadDetailChild,
            ormDraftProposalExpenseLoadDetailChild,
            ormDraftProposalNonENRTFFundLoadDetailChild,
            ormDraftProposalAttachmentLoadDetailChild,
            ormDraftProposalParcelLoadDetailChild,
            ormFinalProposalCollaboratorLoadDetailChild,
            ormFinalProposalActivityLoadDetailChild,
            ormFinalProposalMilestoneLoadDetailChild,
            ormFinalProposalPastProjectLoadDetailChild,
            ormFinalProposalPersonnelLoadDetailChild,
            ormFinalProposalContractLoadDetailChild,
            ormFinalProposalExpenditureLoadDetailChild,
            ormFinalProposalAcquisitionLoadDetailChild,
            ormFinalProposalTravelLoadDetailChild,
            ormFinalProposalPrintingLoadDetailChild,
            ormFinalProposalExpenseLoadDetailChild,
            ormFinalProposalNonENRTFFundLoadDetailChild,
            ormFinalProposalAttachmentLoadDetailChild,
            ormFinalProposalParcelLoadDetailChild,
            ormStatusUpdateLoadDetailChild,
            ormStatusUpdateActivityLoadDetailChild,
            ormAmendmentLogLoadDetailChild,
            ormManagerRequestLoadDetailChild,
            ormCompletionRequestLoadDetailChild,
            match,
            synced,
            authState
        } = this.props;
        const { firstLoaded, isDraft, loaded, reachedNotReady, reviewLoaded } = this.state;

        // If the plan hasn't been loaded yet - most likely because staff is viewing the record
        // Then we must query the server directly because staff can't load in every single proposal at once
        // Regular uses would know the ids of every proposal through ProposalView but we are checking if they really are staff on the server before rturning all data regarding proposal
        if ((!proposal || !plan) && authState && authState.user && reachedNotReady && synced && synced.ready) {
            ormProposalLoadDetail(match.params.id);

            if (isDraft) {
                ormDraftWorkPlanLoadDetailChild(match.params.id);
            } else {
                ormFinalWorkPlanLoadDetailChild(match.params.id);
            }
            this.setState({ firstLoaded: true });
        }

        if (!reviewLoaded && authState && authState.user && synced && synced.ready && (firstLoaded || authState.user.role === "User")) {
            ormWorkPlanReviewLoadDetailChildFilterMany({ proposal: match.params.id });
            ormWorkPlanCommentReloadOverWrite({ proposal: match.params.id });
            this.setState({ reviewLoaded: true });
        }

        if (plan && authState && authState.user && synced && synced.ready && (authState.user.role !== "User" || authState.user.is_read_only) && !loaded) { // && firstLoaded
            if (isDraft) {
                //ormDraftWorkPlanLoadDetailChild(plan.id);
                ormDraftProposalCollaboratorLoadDetailChild(plan.id);
                ormDraftProposalActivityLoadDetailChild(plan.id);
                ormDraftProposalMilestoneLoadDetailChild(plan.id);
                ormDraftProposalPastProjectLoadDetailChild(plan.id);
                ormDraftProposalPersonnelLoadDetailChild(plan.id);
                ormDraftProposalContractLoadDetailChild(plan.id);
                ormDraftProposalExpenditureLoadDetailChild(plan.id);
                ormDraftProposalAcquisitionLoadDetailChild(plan.id);
                ormDraftProposalTravelLoadDetailChild(plan.id);
                ormDraftProposalPrintingLoadDetailChild(plan.id);
                ormDraftProposalExpenseLoadDetailChild(plan.id);
                ormDraftProposalNonENRTFFundLoadDetailChild(plan.id);
                ormDraftProposalAttachmentLoadDetailChild(plan.id);
                ormDraftProposalParcelLoadDetailChild(plan.id);
            } else {
                //ormFinalWorkPlanLoadDetailChild(match.params.id);
                ormFinalProposalCollaboratorLoadDetailChild(plan.id);
                ormFinalProposalActivityLoadDetailChild(plan.id);
                ormFinalProposalMilestoneLoadDetailChild(plan.id);
                ormFinalProposalPastProjectLoadDetailChild(plan.id);
                ormFinalProposalPersonnelLoadDetailChild(plan.id);
                ormFinalProposalContractLoadDetailChild(plan.id);
                ormFinalProposalExpenditureLoadDetailChild(plan.id);
                ormFinalProposalAcquisitionLoadDetailChild(plan.id);
                ormFinalProposalTravelLoadDetailChild(plan.id);
                ormFinalProposalPrintingLoadDetailChild(plan.id);
                ormFinalProposalExpenseLoadDetailChild(plan.id);
                ormFinalProposalNonENRTFFundLoadDetailChild(plan.id);
                ormFinalProposalAttachmentLoadDetailChild(plan.id);
                ormFinalProposalParcelLoadDetailChild(plan.id);
                ormStatusUpdateLoadDetailChild(plan.id);
                ormStatusUpdateActivityLoadDetailChild(plan.id);
                ormAmendmentLogLoadDetailChild(plan.id);
                ormManagerRequestLoadDetailChild(match.params.id);
                ormCompletionRequestLoadDetailChild(match.params.id);
            }
            this.setState({ loaded: true });
        }
    }

    // Copy of componentDidMount incase they load another proposal
    componentDidUpdate() {
        const {
            synced,
            plan,
            proposal,
            ormWorkPlanReviewLoadDetailChildFilterMany,
            ormWorkPlanCommentReloadOverWrite,
            ormProposalLoadDetail,
            ormDraftWorkPlanLoadDetailChild,
            ormFinalWorkPlanLoadDetailChild,
            ormDraftProposalCollaboratorLoadDetailChild,
            ormDraftProposalActivityLoadDetailChild,
            ormDraftProposalMilestoneLoadDetailChild,
            ormDraftProposalPastProjectLoadDetailChild,
            ormDraftProposalPersonnelLoadDetailChild,
            ormDraftProposalContractLoadDetailChild,
            ormDraftProposalExpenditureLoadDetailChild,
            ormDraftProposalAcquisitionLoadDetailChild,
            ormDraftProposalTravelLoadDetailChild,
            ormDraftProposalPrintingLoadDetailChild,
            ormDraftProposalExpenseLoadDetailChild,
            ormDraftProposalNonENRTFFundLoadDetailChild,
            ormDraftProposalAttachmentLoadDetailChild,
            ormDraftProposalParcelLoadDetailChild,
            ormFinalProposalCollaboratorLoadDetailChild,
            ormFinalProposalActivityLoadDetailChild,
            ormFinalProposalMilestoneLoadDetailChild,
            ormFinalProposalPastProjectLoadDetailChild,
            ormFinalProposalPersonnelLoadDetailChild,
            ormFinalProposalContractLoadDetailChild,
            ormFinalProposalExpenditureLoadDetailChild,
            ormFinalProposalAcquisitionLoadDetailChild,
            ormFinalProposalTravelLoadDetailChild,
            ormFinalProposalPrintingLoadDetailChild,
            ormFinalProposalExpenseLoadDetailChild,
            ormFinalProposalNonENRTFFundLoadDetailChild,
            ormFinalProposalAttachmentLoadDetailChild,
            ormFinalProposalParcelLoadDetailChild,
            ormStatusUpdateLoadDetailChild,
            ormStatusUpdateActivityLoadDetailChild,
            ormAmendmentLogLoadDetailChild,
            ormManagerRequestLoadDetailChild,
            ormCompletionRequestLoadDetailChild,
            match,
            authState
        } = this.props;
        const { firstLoaded, isDraft, loaded, reachedNotReady, reviewLoaded } = this.state;

        if ((!proposal || !plan) && authState && authState.user && synced && synced.ready) {
            ormProposalLoadDetail(match.params.id);

            if (isDraft) {
                ormDraftWorkPlanLoadDetailChild(match.params.id);
            } else {
                ormFinalWorkPlanLoadDetailChild(match.params.id);
            }
            this.setState({ firstLoaded: true });
        }

        if (!reviewLoaded && authState && authState.user && synced && synced.ready && (firstLoaded || authState.user.role === "User")) {
            ormWorkPlanReviewLoadDetailChildFilterMany({ proposal: match.params.id });
            ormWorkPlanCommentReloadOverWrite({ proposal: match.params.id });
            this.setState({ reviewLoaded: true });
        }

        if (!reachedNotReady && !synced.ready) {
            this.setState({ reachedNotReady: true });
        }

        if (plan && authState && authState.user && synced && synced.ready && (authState.user.role !== "User" || authState.user.is_read_only) && !loaded) { // && firstLoaded
            if (isDraft) {
                //ormDraftWorkPlanLoadDetailChild(plan.id);
                ormDraftProposalCollaboratorLoadDetailChild(plan.id);
                ormDraftProposalActivityLoadDetailChild(plan.id);
                ormDraftProposalMilestoneLoadDetailChild(plan.id);
                ormDraftProposalPastProjectLoadDetailChild(plan.id);
                ormDraftProposalPersonnelLoadDetailChild(plan.id);
                ormDraftProposalContractLoadDetailChild(plan.id);
                ormDraftProposalExpenditureLoadDetailChild(plan.id);
                ormDraftProposalAcquisitionLoadDetailChild(plan.id);
                ormDraftProposalTravelLoadDetailChild(plan.id);
                ormDraftProposalPrintingLoadDetailChild(plan.id);
                ormDraftProposalExpenseLoadDetailChild(plan.id);
                ormDraftProposalNonENRTFFundLoadDetailChild(plan.id);
                ormDraftProposalAttachmentLoadDetailChild(plan.id);
                ormDraftProposalParcelLoadDetailChild(plan.id);
            } else {
                //ormFinalWorkPlanLoadDetailChild(match.params.id);
                ormFinalProposalCollaboratorLoadDetailChild(plan.id);
                ormFinalProposalActivityLoadDetailChild(plan.id);
                ormFinalProposalMilestoneLoadDetailChild(plan.id);
                ormFinalProposalPastProjectLoadDetailChild(plan.id);
                ormFinalProposalPersonnelLoadDetailChild(plan.id);
                ormFinalProposalContractLoadDetailChild(plan.id);
                ormFinalProposalExpenditureLoadDetailChild(plan.id);
                ormFinalProposalAcquisitionLoadDetailChild(plan.id);
                ormFinalProposalTravelLoadDetailChild(plan.id);
                ormFinalProposalPrintingLoadDetailChild(plan.id);
                ormFinalProposalExpenseLoadDetailChild(plan.id);
                ormFinalProposalNonENRTFFundLoadDetailChild(plan.id);
                ormFinalProposalAttachmentLoadDetailChild(plan.id);
                ormFinalProposalParcelLoadDetailChild(plan.id);
                ormStatusUpdateLoadDetailChild(plan.id);
                ormStatusUpdateActivityLoadDetailChild(plan.id);
                ormAmendmentLogLoadDetailChild(plan.id);
                ormManagerRequestLoadDetailChild(match.params.id);
                ormCompletionRequestLoadDetailChild(match.params.id);
            }
            this.setState({ loaded: true });
        }

        if (proposal && plan) {
            if (
                this.props.location.search &&
                this.props.location.search !== "" &&
                !this.state.changedStep &&
                this.props.synced &&
                this.props.synced.ready &&
                reachedNotReady
            ) {
                this.setState({ changedStep: true });
                this.handleStep(parseInt(this.props.location.search.split("=")[1]), false, 0);
            }
        }

        if (authState.user && authState.user.role === "Member" && proposal) {
            if (this.state.isDraft) {
                if (
                    ![
                        "Draft Work Plan Accepted",
                        "Final Work Plan Due",
                        "Final Work Plan Submitted",
                        "Final Work Plan Revisions Needed",
                        "Final Work Plan Revisions Submitted"
                    ].includes(proposal.status)
                ) {
                    this.props.history.push("/dashboard");
                }
            } else {
                if (!["Final Work Plan Accepted", "Final Work Plan Approved", "Project Completed"].includes(proposal.status)) {
                    this.props.history.push("/dashboard");
                }
            }
        }

        //this catches changing url between draft and final
        if (isDraft !== (this.props.match.path === "/draft/workplan/:id")) {
            this.setState({
                isDraft: this.props.match.path === "/draft/workplan/:id",
                ormPropPrefix: this.props.match.path === "/draft/workplan/:id" ? "Draft" : "Final"
            });
        }
    }

    actionMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    actionMenuClose = () => {
        this.setState({ anchorEl: null });
    };
    goLog = () => {
        this.props.history.push("/activity/log/" + this.props.proposal.id);
    };

    approveCombined = async () => {
        const { ormStatusUpdateUpdate, status_updates, history, status_update_activities, plan, ormFinalWorkPlanUpdate, amendments } = this.props;
        var status_update = status_updates.filter(su => !su.approved_date && su.submitted_date);
        var amendment = amendments[0];
        var approved = true;

        status_update.forEach(function(su) {
            if (!su.dissemination_update_staff || !su.overall_update_staff) {
                approved = false;
            }
            status_update_activities
                .filter(n => n.status_update === su.id)
                .forEach(function(a) {
                    if (!a.activity_complete_staff) approved = false;
                });
        });

        if (!approved) {
            alert("Staff must review and approve all activity items before they can approve a status update.");
            return;
        }

        if (!amendment.staff_reviewed) {
            alert("Staff have not reviewed the amendment in the table.")
            return;
        }

        this.setState({adminSaving: true});
        var d = new Date();
        var is_final = false;
        
        status_update.forEach(function(su) {
            if (su.is_final)
                is_final = true;
            su.approved_date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
            ormStatusUpdateUpdate({
                id: su.id,
                ...su
            });
        });     
        await ormFinalWorkPlanUpdate({
            id: plan.id,
            status: "Amendment Approved"
        });   

        if (is_final) {
            this.setState({adminSaving: false, finalUpdateDue: true});
        } else {
            setTimeout(function() {
                history.push("/dashboard/");
            }, 30000);
        }
    };

    approveStatusUpdate = async () => {
        const { ormStatusUpdateUpdate, status_updates, history, status_update_activities } = this.props;
        var status_update = status_updates.filter(su => !su.approved_date && su.submitted_date);
        var approved = true;

        status_update.forEach(function(su) {
            if (!su.dissemination_update_staff || !su.overall_update_staff) {
                approved = false;
            }
            status_update_activities
                .filter(n => n.status_update === su.id)
                .forEach(function(a) {
                    if (!a.activity_complete_staff) approved = false;
                });
        });
    
        if (!approved) {
            alert("Staff must review and approve all activity items before they can approve a status update.");
            return;
        }

        this.setState({adminSaving: true});
        var d = new Date();
        var is_final = false;

        status_update.forEach(function(su) {
            if (su.is_final)
                is_final = true;
            su.approved_date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
            ormStatusUpdateUpdate({
                id: su.id,
                ...su
            });
        });

        if (is_final) {
            this.setState({adminSaving: false, finalUpdateDue: true});
        } else {
            setTimeout(function() {
                history.push("/dashboard/");
            }, 15000);
        }
    };

    denyStatusUpdate = async values => {
        const { ormStatusUpdateUpdate, status_updates, history } = this.props;
        // The last status update was already approved and send back only the last one back for revisions
        var status_update = status_updates[0]; // .filter(su => !su.approved_date) (Should be latest ordered by id)
        if (status_update.approved_date) {
            status_update.submitted_date = null;
            status_update.approved_date = null;
            status_update.has_revisions = true;
            status_update.due_date = values.due_date.substring(0, 10);

            await ormStatusUpdateUpdate({
                id: status_update.id,
                ...status_update
            });
            history.push("/dashboard/");
        } else {
            var status_update_return = status_updates.filter(su => !su.approved_date)
            status_update_return.forEach(function(sur) {
                sur.submitted_date = null;
                sur.approved_date = null;
                sur.has_revisions = true;
                sur.due_date = values.due_date.substring(0, 10);
    
                ormStatusUpdateUpdate({
                    id: sur.id,
                    ...sur
                });
                history.push("/dashboard/");
            });
        }
    };

    approveCompletionDate = async () => {
        const { history, ormCompletionRequestUpdate, completion_requests } = this.props;
        var request = completion_requests[0];

        if (!request.staff_reviewed) {
            alert("Staff have not reviewed the amendment in the table.")
            return;
        }

        this.setState({adminSaving: true});

        await ormCompletionRequestUpdate({
            id: request.id,
            status: "Approved"
        });
        setTimeout(function() {
            history.push("/dashboard/");
        }, 15000);
    };

    denyCompletionDate = async (values) => {
        const { history, ormCompletionRequestUpdate, completion_requests } = this.props;
        var request = completion_requests[0];

        await ormCompletionRequestUpdate({
            id: request.id,
            status: "Declined",
            decline_reason: values.decline_reason
        });
        history.push("/dashboard/");
    };

    approveFinalAbstract = async () => {
        const { history, ormProposalUpdate, proposal } = this.props;

        this.setState({adminSaving: true});

        await ormProposalUpdate({
            id: proposal.id,
            status: "Project Completed"
        });
        setTimeout(function() {
            history.push("/dashboard/");
        }, 15000);
    };

    denyFinalAbstract = async (values) => {
        const { history, ormProposalUpdate, proposal } = this.props;

        await ormProposalUpdate({
            id: proposal.id,
            abstract_due_date: values.due_date.substring(0, 10),
            status: "Final Abstract Revisions Needed"
        });
        history.push("/dashboard/");
    };

    approveProjectManager = async () => {
        const { history, ormManagerRequestUpdate, manager_requests } = this.props;
        var request = manager_requests[0];

        if (!request.staff_reviewed) {
            alert("Staff have not reviewed the amendment in the table.")
            return;
        }

        this.setState({adminSaving: true});

        await ormManagerRequestUpdate({
            id: request.id,
            status: "Approved"
        });
        setTimeout(function() {
            history.push("/dashboard/");
        }, 15000);
    };

    denyProjectManager = async (values) => {
        const { history, ormManagerRequestUpdate, manager_requests } = this.props;
        var request = manager_requests[0];

        await ormManagerRequestUpdate({
            id: request.id,
            status: "Declined",
            decline_reason: values.decline_reason
        });
        history.push("/dashboard/");
    };

    approveAmendment = async () => {
        const { plan, history, ormFinalWorkPlanUpdate, amendments } = this.props;
        var amendment = amendments[0];

        if (!amendment.staff_reviewed) {
            alert("Staff have not reviewed the amendment in the table.")
            return;
        }

        this.setState({adminSaving: true});

        await ormFinalWorkPlanUpdate({
            id: plan.id,
            status: "Amendment Approved"
        });
        setTimeout(function() {
            history.push("/dashboard/");
        }, 15000);
    };

    denyAmendment = async (values) => {
        const { plan, history, ormFinalWorkPlanUpdate } = this.props;

        await ormFinalWorkPlanUpdate({
            id: plan.id,
            status: "Amendment Revisions Needed",
            amendment_due_date: values.due_date.substring(0, 10)
        });
        history.push("/dashboard/");
    };

    deleteStatusUpdate = async () => {
        const { ormStatusUpdateDelete, status_updates } = this.props;
        if (window.confirm("You are about to delete this additional status update. Are you sure you want to do this?")) {
            var status_update = status_updates.filter(su => !su.approved_date && su.is_additional)[0];
            await ormStatusUpdateDelete(status_update.id);
            window.location.reload();
        }
    };

    revertAmendment = () => {
        const { plan, authState } = this.props;
        this.setState({ loadingAmendment: true });
        fetch("amendment/stop/" + plan.id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        }).then(e => {
            window.location.reload();
        });
    };

    requestAmendment = () => {
        const { plan, authState, proposal } = this.props;
        this.setState({ loadingAmendment: true });
        fetch("amendment/start/" + plan.id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify({
                from_completed: proposal.status,
            })
        }).then(e => {
            window.location.reload();
        });
    };

    ActionMenu = ({
        options,
        actionMenuOpen,
        actionMenuClose,
        anchorEl,
        plan,
        comments,
        proposal,
        classes,
        authState,
        ormPropPrefix,
        proposalDelegates,
        manager_requests,
        completion_requests,
        ormManagerRequestDelete,
        ormCompletionRequestDelete,
        history,
        status_updates,
        ...rest
    }) => {
        var is_admin = false;
        if (authState && authState.user && authState.user.role === "Staff" && authState.user.email !== "monica.weber@state.mn.us") is_admin = true;
        var is_dnr = false;
        if (authState && authState.user && (authState.user.is_read_only || authState.user.role === "Member")) is_dnr = true;
        var is_member = false;
        if (authState && authState.user && authState.user.role === "Member") is_member = true;

        var pds = [];
        if (!is_admin) {
            proposalDelegates.forEach(function(pD) {
                if (pD.proposal === proposal.id) pds.push(pD.id);
            });
        }

        // Draft Work Plan: (isDraft)
        // proposal.status = Draft Work Plan Due, Draft Work Plan Submitted, Draft Work Plan Revisions Needed, Draft Work Plan Revisions Submitted, Draft Work Plan Accepted

        // Final Work Plan (!isDraft)
        // proposal.status = Final Work Plan Due, Final Work Plan Submitted, Final Work Plan Revisions Needed, Final Work Plan Revisions Submitted, Final Work Plan Accepted

        // Active Project
        // proposal.status = Final Work Plan Approved

        // Status Update
        // plan.reporting_status = Update Due,
        // Update Submitted, Update Revisions Needed, Update Revisions Submitted, Update Approved,
        // Additional Update Submitted, Additional Update Revisions Needed, Additional Update Revisions Submitted, Additional Update Approved

        // Amendment
        // plan.status = Amendment In Progress, Amendment Submitted, Amendment Approved, Amendment Revisions Needed, Amendment Revisions Submitted

        const wrongView = (this.state.isDraft && !proposal.status.includes("Draft")) || (!this.state.isDraft && proposal.status.includes("Draft"));

        /*const approve_status_update = (is_admin && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && (plan.reporting_status === "Update Submitted" || plan.reporting_status === "Final Update Submitted" ||
            plan.reporting_status === "Additional Update Submitted" || plan.reporting_status === "Update Revisions Submitted" || plan.reporting_status === "Final Update Revisions Submitted" || plan.reporting_status === "Additional Update Revisions Submitted"
        ));*/
        const approve_status_update = (is_admin && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && status_updates.filter(su => !su.approved_date && su.submitted_date).length > 0);

        const deny_status_update = (is_admin && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && (plan.reporting_status === "Update Submitted" || plan.reporting_status === "Final Update Submitted" ||
            plan.reporting_status === "Additional Update Submitted" || plan.reporting_status === "Update Revisions Submitted" || plan.reporting_status === "Final Update Revisions Submitted" || plan.reporting_status === "Additional Update Revisions Submitted" ||
            plan.reporting_status === "Update Approved" || plan.reporting_status === "Final Update Approved" || plan.reporting_status === "Additional Update Approved"
        ));
        const approve_deny_amendment = (is_admin && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && (plan.status === "Amendment Submitted" || plan.status === "Amendment Revisions Submitted"));
        return (
            <div style={{ float: "right" }}>
                <Button variant="contained" color="primary" onClick={actionMenuOpen}>
                    <BuildIcon />
                    &nbsp;&nbsp;&nbsp;Select Action
                </Button>
                <Menu role="main" className={classes.list} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={actionMenuClose}>
                    {proposal.status !== "Final Work Plan Approved" && proposal.status !== "Final Abstract Due" && proposal.status !== "Final Abstract Submitted" && proposal.status !== "Final Abstract Revisions Needed" && proposal.status !== "Project Completed" && ( // Draft/Final Work Plan Report
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                if (this.state.isDraft)
                                    window.open(`/reports/workplan/${plan.id}?draft=y`)
                                else
                                    window.open(`/reports/workplan/${plan.id}`)
                                }}>
                            <ListItemIcon>
                                <GetApp />
                            </ListItemIcon>
                            <ListItemText primary="Generate Report (Word Doc)" />
                        </MenuItem>
                    )}
                    {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && ( // Active Project - Show track changes report
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                if (is_member)
                                    window.open(`/reports/workplan/${plan.id}?member=true`)
                                else
                                    window.open(`/reports/amendment/${plan.id}`)
                            }}>
                            <ListItemIcon>
                                <GetApp />
                            </ListItemIcon>
                            <ListItemText primary={proposal.status === "Project Completed" ? "Generate Approved Final Report (Word Doc)" : "Generate Report (Word Doc)"}/>
                        </MenuItem>
                    )}
                    {is_admin && !is_dnr && (proposal.status !== "Final Work Plan Approved" && proposal.status !== "Final Abstract Due" && proposal.status !== "Final Abstract Submitted" && proposal.status !== "Final Abstract Revisions Needed" && proposal.status !== "Project Completed") && ( // Staff only comparision Report
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                window.open(`reports/comparison/${this.state.isDraft ? "proposal" : "plan"}/${plan.proposal_record_id}/${plan.id}`)
                            }}>
                            <ListItemIcon>
                                <GetApp />
                            </ListItemIcon>
                            <ListItemText primary="Generate Comparison Report (Word Doc)" />
                        </MenuItem>
                    )}
                    {!is_admin && !is_dnr && proposal.user_id === authState.user.id && ( // Delegate Access
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => this.setState({ delegateDialog: true, formkey: window.performance.now() })}>
                            <ListItemIcon>
                                <GroupAddIcon />
                            </ListItemIcon>
                            <ListItemText primary="Delegate Access" />
                        </MenuItem>
                    )}
                    {pds.length > 0 && !is_dnr && proposal.user_id === authState.user.id && ( // Delegate Access
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => this.setState({ deleteOptions: pds, deleteDelegateDialog: true })}>
                            <ListItemIcon>
                                <PersonAddDisabled />
                            </ListItemIcon>
                            <ListItemText primary="Remove Delegate Access" />
                        </MenuItem>
                    )}
                    {!is_admin && !is_dnr &&
                        this.state.isDraft &&
                        plan.user_id === authState.user.id &&
                        allowedWithDrawal.includes(proposal.primary_phase) &&
                        proposal.secondary_status !== "Withdrawal Requested" &&
                        proposal.secondary_status !== "Withdrawn" && (
                            <MenuItem className={classes.listItem} onClick={() => this.setState({ reqeuestWidrawal: true })}>
                                <ListItemIcon>
                                    <MailIcon />
                                </ListItemIcon>
                                <ListItemText primary="Request Withdrawal" />
                            </MenuItem>
                        )}
                    {is_admin && !is_dnr && plan.activeStep && plan.activeStep !== 0 && plan.activeStep !== 9 && workPlanRevisions2.includes(proposal.status) && (
                        <MenuItem className={classes.listItem} onClick={() => this.setState({ addStaffComment: true })}>
                            <ListItemIcon>
                                <CommentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Add Staff Comment" />
                        </MenuItem>
                    )}
                    {is_admin && !is_dnr && workPlanRevisions.includes(proposal.status) && !wrongView && (
                        <MenuItem className={classes.listItem} onClick={() => this.setState({ revisionOpen: true })}>
                            <ListItemIcon>
                                <EventIcon />
                            </ListItemIcon>
                            <ListItemText primary="Set Due Date and Send Work Plan Back to Applicant for Revisions" />
                        </MenuItem>
                    )}
                    {is_admin && !is_dnr &&
                        (proposal.status === "Draft Work Plan Submitted" ||
                            proposal.status === "Draft Work Plan Revisions Needed" ||
                            proposal.status === "Draft Work Plan Revisions Submitted") &&
                        proposal.secondary_status !== "Withdrawal Requested" &&
                        proposal.secondary_status !== "Withdrawn" && (
                            <MenuItem className={classes.listItem} onClick={() => this.setState({ acceptPlanDialog: true })}>
                                <ListItemIcon>
                                    <EventIcon />
                                </ListItemIcon>
                                <ListItemText primary="Accept Draft Work Plan" />
                            </MenuItem>
                        )}
                    {is_admin && !is_dnr &&
                        (proposal.status === "Final Work Plan Submitted" ||
                            proposal.status === "Final Work Plan Revisions Needed" ||
                            proposal.status === "Final Work Plan Revisions Submitted") &&
                        proposal.secondary_status !== "Withdrawal Requested" &&
                        proposal.secondary_status !== "Withdrawn" && (
                            <MenuItem className={classes.listItem} onClick={() => this.setState({ acceptPlanDialog: true })}>
                                <ListItemIcon>
                                    <EventIcon />
                                </ListItemIcon>
                                <ListItemText primary="Accept Final Work Plan" />
                            </MenuItem>
                        )}
                    {!is_admin && !is_dnr &&
                        (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") &&
                        /*(plan.reporting_status === "-" ||
                            plan.reporting_status === "Update Approved" ||
                            plan.reporting_status === "Final Update Approved" ||
                            plan.reporting_status === "Additional Update Approved") &&*/ (
                            <MenuItem className={classes.listItem}onClick={() => this.createStatusUpdate(plan.id)}>
                                <ListItemIcon>
                                    <AlarmIcon />
                                </ListItemIcon>
                                <ListItemText primary="Add Additional Update" />
                            </MenuItem>
                        )}
                    {!is_admin && !is_dnr && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && (plan.reporting_status === "Update Due" || plan.reporting_status === "Final Update Due" || plan.reporting_status === "Additional Update In Progress") && plan.is_additional && (
                        <MenuItem className={classes.listItem} onClick={() => this.deleteStatusUpdate(plan.id)}>
                            <ListItemIcon>
                                <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary="Delete Additional Status Update" />
                        </MenuItem>
                    )}
                    {!is_admin && !is_dnr &&
                        (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") &&
                        (!plan.status || plan.status === "Amendment Approved") && (
                            <MenuItem
                                onClick={() => {
                                    this.setState({ amendmentStartOpen: true });
                                }}
                                className={classes.listItem}>
                                <ListItemIcon>
                                    <Create />
                                </ListItemIcon>
                                <ListItemText primary="Initiate Amendment Request" />
                            </MenuItem>
                        )}
                    {!is_admin && !is_dnr && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && (plan.status === "Amendment In Progress" || plan.status === "Amendment Revisions Needed") && (
                        <MenuItem
                            onClick={() => {
                                this.setState({ amendmentEndOpen: true });
                            }}
                            className={classes.listItem}>
                            <ListItemIcon>
                                <CloseIcon />
                            </ListItemIcon>
                            <ListItemText primary="Withdraw Amendment Request" />
                        </MenuItem>
                    )}
                    {(approve_status_update || approve_deny_amendment) && !is_dnr && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                if (approve_status_update && approve_deny_amendment)
                                    this.approveCombined();
                                else if  (approve_status_update)
                                    this.approveStatusUpdate();
                                else if (approve_deny_amendment)
                                    this.approveAmendment();
                            }}>
                            <ListItemIcon>
                                <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary={(approve_status_update && approve_deny_amendment) ? "Approve Update and Amendment" : approve_status_update ? "Approve Update" : "Approve Amendment"} />
                        </MenuItem>
                    )}
                    {(deny_status_update || approve_status_update || approve_deny_amendment) && !is_dnr && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                if ((deny_status_update || approve_status_update) && approve_deny_amendment)
                                    this.setState({ statusUpdateChangeDate: true, denyContents: "both" });
                                else if (deny_status_update || approve_status_update)
                                    this.setState({ statusUpdateChangeDate: true, denyContents: "status" });
                                else
                                    this.setState({ statusUpdateChangeDate: true, denyContents: "amendment" });
                            }}>
                            <ListItemIcon>
                                <BackspaceIcon />
                            </ListItemIcon>
                            <ListItemText primary={((deny_status_update || approve_status_update) && approve_deny_amendment) ? "Send Update and Amendment Back For Revisions" : (deny_status_update || approve_status_update)  ? "Send Update Back For Revisions" : "Send Amendment Back For Revisions"} />
                        </MenuItem>
                    )}
                    {(approve_deny_amendment && (deny_status_update || approve_status_update)) && !is_dnr && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                this.setState({ statusUpdateChangeDate: true, denyContents: "amendment" });
                            }}>
                            <ListItemIcon>
                                <BackspaceIcon />
                            </ListItemIcon>
                            <ListItemText primary="Send Amendment Back For Revisions" />
                        </MenuItem>
                    )}
                    {!is_admin && !is_dnr && manager_requests.length > 0 && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                ormManagerRequestDelete(manager_requests[0].id);
                            }}>
                            <ListItemIcon>
                                <CloseIcon />
                            </ListItemIcon>
                            <ListItemText primary="Withdraw Project Manager Change Request" />
                        </MenuItem>
                    )}
                    {is_admin && !is_dnr && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && manager_requests.length > 0 && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                this.approveProjectManager();
                            }}>
                            <ListItemIcon>
                                <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary="Approve Project Manager Change Request" />
                        </MenuItem>
                    )}
                    {is_admin && !is_dnr && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && manager_requests.length > 0 && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                this.setState({ denyProjectManagerState: true });
                            }}>
                            <ListItemIcon>
                                <CloseIcon />
                            </ListItemIcon>
                            <ListItemText primary="Deny Project Manager Change Request" />
                        </MenuItem>
                    )}
                    {is_admin && !is_dnr && proposal.status !== "Final Work Plan Approved" && proposal.status !== "Final Abstract Due" && proposal.status !== "Final Abstract Submitted" && proposal.status !== "Final Abstract Revisions Needed" && manager_requests.length > 0 && (
                        <MenuItem
                            style={{ borderBottom: "1px solid #666666" }}
                            onClick={() => {
                                history.push("/adm/manager_request/" + manager_requests[0].id);
                            }}>
                            <ListItemIcon>
                            <Visibility />
                            </ListItemIcon>
                            <ListItemText primary="Review Request To Change Project Manager" />
                        </MenuItem>
                    )}
                    
                    {!is_admin && !is_dnr && completion_requests.length > 0 && completion_requests[0].status === "New" && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                ormCompletionRequestDelete(completion_requests[0].id);
                            }}>
                            <ListItemIcon>
                                <CloseIcon />
                            </ListItemIcon>
                            <ListItemText primary="Withdraw Completion Date Change Request" />
                        </MenuItem>
                    )}


                    {is_admin && !is_dnr && (proposal.status === "Final Abstract Submitted") && 
                    (plan.reporting_status === "-" ||
                            plan.reporting_status === "Update Approved" ||
                            plan.reporting_status === "Final Update Approved" ||
                            plan.reporting_status === "Additional Update Approved") &&
                    (!plan.status || plan.status === "Amendment Approved") && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                this.approveFinalAbstract();
                            }}>
                            <ListItemIcon>
                                <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary="Approve Final Abstract" />
                        </MenuItem>
                    )}
                    {is_admin && !is_dnr && (proposal.status === "Final Abstract Submitted" || proposal.status === "Project Completed") && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                this.setState({ abstractChangeDate: true });
                            }}>
                            <ListItemIcon>
                                <CloseIcon />
                            </ListItemIcon>
                            <ListItemText primary="Send Final Abstract Back For Revisions" />
                        </MenuItem>
                    )}


                    {is_admin && !is_dnr && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && completion_requests.length > 0 && completion_requests[0].status === "New" && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                this.approveCompletionDate();
                            }}>
                            <ListItemIcon>
                                <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary="Approve Completion Date Change Request" />
                        </MenuItem>
                    )}
                    {is_admin && !is_dnr && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && completion_requests.length > 0 && completion_requests[0].status === "New" && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                this.setState({ denyCompletionManagerState: true });
                            }}>
                            <ListItemIcon>
                                <CloseIcon />
                            </ListItemIcon>
                            <ListItemText primary="Deny Completion Date Change Request" />
                        </MenuItem>
                    )}
                    <MenuItem
                        className={classes.listItem}
                        onClick={this.goLog}>
                        <ListItemIcon>
                            <Visibility />
                        </ListItemIcon>
                        <ListItemText primary="View Activity Log" />
                    </MenuItem>
                    {this.state.isDraft && (
                        <MenuItem style={{ borderBottom: "1px solid #666666", backgroundColor: "#eee", cursor: "default" }}>
                            <ListItemText primary={"Work Plan Status: " + proposal.status} />
                        </MenuItem>
                    )}
                </Menu>
            </div>
        );
    };

    // Sets keepState to Keep Comments and Revisions tab still with submitted status.
    keepState = () => {
        this.setState({ keepState: true });
    };

    go = e => {
        e.preventDefault();
        this.props.history.push({ hash: "skipprojectnavigation", search: this.props.location.search });
        e.target.blur();
        this.targetRef.current.scrollIntoView();
        this.targetRef.current.focus();
    };

    getSubStep = (steps) => {
        const { plan, proposal } = this.props;
        if (plan.activeStep === 1)
            return getCollaboratorSteps()[plan.activeSubStep]
        if (plan.activeStep === 5)
            return getBudgetSteps()[plan.activeSubStep]
        if (plan.activeStep === 7)
            return getParcelSteps()[plan.activeSubStep]
        if (steps && plan.activeStep === steps.indexOf("Reporting"))
            return getReportingSteps(proposal.status)[plan.activeSubStep]
        return null;
    }

    render() {
        const {
            classes,
            comments,
            proposal,
            authState,
            rfps,
            ormProposalDelegateReload,
            ormProposalDelegateDelete,
            proposalDelegates,
            plan,
            helpmessages,
            manager_requests,
            ormManagerRequestDelete,
            ormManagerRequestUpdate,
            completion_requests,
            ormCompletionRequestDelete,
            ormCompletionRequestUpdate,
            history,
            status_updates
        } = this.props;
        const { acceptPlanDialog, isDraft, delegateDialog, deleteDelegateDialog, formkey, ormPropPrefix, keepState, viewMode, loadingAmendment, denyProjectManagerState, denyCompletionManagerState, statusUpdateChangeDate, abstractChangeDate, denyContents } = this.state;
        if (!plan || !proposal || loadingAmendment) {
            return <AppContainer authenticated synced={true} />;
        }
        const Restoration = getValue(plan, "land_rights").indexOf("Restoration") > -1;
        const Conservation = getValue(plan, "land_rights").indexOf("Conservation Easement Acquisition") > -1;
        const Easement = getValue(plan, "land_rights").indexOf("Easement (Other) Acquisition") > -1;
        const Fee = getValue(plan, "land_rights").indexOf("Fee Acquisition") > -1;

        const settings = rfps.find(x => x.year === plan.ml_year);

        var ormParentUpdate;
        var ormParentUpdateLocalOnly;

        if (isDraft) {
            ormParentUpdate = this.props.ormDraftWorkPlanUpdate;
            ormParentUpdateLocalOnly = this.props.ormDraftWorkPlanUpdateLocalOnly;
        } else {
            ormParentUpdate = this.props.ormFinalWorkPlanUpdate;
            ormParentUpdateLocalOnly = this.props.ormFinalWorkPlanUpdateLocalOnly;
        }

        //check if user can score proposals
        const reviewScoring = authState && authState.user && authState.user.role === "Staff";
        const isUser = authState && authState.user && authState.user.role === "User";
        const isDNR = authState && authState.user && (authState.user.is_read_only || authState.user.role === "Member");
        const isMember = authState && authState.user && authState.user.role === "Member";
        const hideComments = !proposal.status.includes("Due") && !proposal.status.includes("Needed") && !keepState;

        var steps = getSteps(isDraft, reviewScoring, isUser, hideComments, proposal.status, isDNR, isMember);
        var member = false; // eslint-disable-line no-unused-vars
        var user = false;
        var onlyReview = false; // eslint-disable-line no-unused-vars
        var lockAll = false;

        if (reviewScoring) {
            if (proposal.status === "Draft Work Plan Due" || proposal.status === "Final Work Plan Due" || proposal.status === "Draft Work Plan Revisions Needed" || proposal.status === "Final Work Plan Revisions Needed") {
                if (plan.activeStep !== 1)
                    plan.activeStep = 9;
                plan.activeSubStep = 0;
                lockAll = true;
            }
        } else {
            if (authState.user && authState.user.role === "User" && proposal.status !== "Draft Work Plan Due" && proposal.status !== "Final Work Plan Due" && proposal.status !== "Draft Work Plan Revisions Needed" && proposal.status !== "Final Work Plan Revisions Needed" && !keepState) {
                user = true;
                onlyReview = true;
            } else if (authState.user && authState.user.role === "User") {
                user = true;
            } else {
                member = true;
            }
        }

        var is_read_only = false;

        if (this.props.authState && this.props.authState.user && (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only)) {
            is_read_only = true;
        } else if ((isDraft && proposal.status.includes("Final")) || (!isDraft && proposal.status.includes("Draft"))) {
            is_read_only = true;
        } else if (
            !editWorkPlanStatuses.includes(proposal.status) ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn"
        ) {
            is_read_only = true;
        }
        if (isUser && !isDNR && (plan.status === "Amendment In Progress" || plan.status === "Amendment Revisions Needed")) is_read_only = false;

        var page_title = "Work Plan ";
        if (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Final Abstract Revisions Submitted") {
            page_title = "Active Project ";
            if (plan.status === "Amendment In Progress" || plan.status === "Amendment Revisions Needed") page_title = "Active Project Amendment Request View ";
            else if (plan.status === "Amendment Submitted" || plan.status === "Amendment Revisions Submitted") page_title = "Active Project Pending Amendment ";
            else if (plan.includes_amendment === "Yes") page_title = "Active Project Amended ";
        }
        if (proposal.status === "Project Completed") {
            page_title = "Completed Project ";

            // Revised Final Report
        }

        return (
            <AppContainer authenticated innerRef={this.appContainer}>
                <Grid container spacing={16}>
                    <a
                        href={`#${this.props.location.pathname}${
                            this.props.location.search ? this.props.location.search : ""
                        }`}
                        onClick={this.go}
                        class="skip-navigation">
                        Skip project navigation
                    </a>
                    <PageHeader 
                        title={page_title + plan.proposal_unique_id + ": " + plan.name} 
                        activeStep={steps[plan.activeStep]}
                        activeSubStep={this.getSubStep(steps)}
                    />
                    <Grid item xs={12} className={classes.stepperWidth}>
                        <Stepper nonLinear className={classes.stepRoot} alternativeLabel activeStep={plan.activeStep || 0}>
                            {steps.map((label, index) => {
                                if ((index === 7 && !Restoration && !Conservation && !Easement && !Fee) || (lockAll && index !== 9 && index !== 1)) {
                                    return (
                                        <Tooltip
                                            title={
                                                lockAll
                                                    ? "Staff cannot view while Project Manager is working on their work plan"
                                                    : "You must select one of the options for the question 'Does your project include restoration or acquisition of land rights' under the General Step"
                                            }>
                                            <Step key={label}>
                                                <StepButton
                                                    disabled
                                                    className={classes.btnroot}
                                                    focusVisibleClassName={classes.pointerr}
                                                    onClick={() => this.handleStep(index, true)}>
                                                    <StepLabel
                                                        StepIconComponent={LockIcon}
                                                        classes={{
                                                            labelContainer: classes.lessMargin
                                                        }}>
                                                        {label}
                                                    </StepLabel>
                                                </StepButton>
                                            </Step>
                                        </Tooltip>
                                    );
                                }

                                return (
                                    <Step key={label} className={plan.activeStep !== index ? classes.pointer : classes.pointer2}>
                                        <StepButton
                                            className={classes.btnroot}
                                            focusVisibleClassName={classes.pointerr}
                                            onClick={() => this.handleStep(index, true)}>
                                            <StepLabel
                                                classes={{
                                                    labelContainer: classes.lessMargin,
                                                    iconContainer: plan.activeStep !== index && classes.stepColor
                                                }}>
                                                {label}
                                            </StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        
                        <a // eslint-disable-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
                            tabIndex="-1"
                            id={`project${this.props.location.pathname}${
                                this.props.location.search ? this.props.location.search : ""
                            }`}
                            name={`${this.props.location.pathname}${
                                this.props.location.search ? this.props.location.search : ""
                            }`}
                            ref={this.targetRef}
                        />
                        <div style={{ marginRight: 30, marginLeft: 30 }}>
                            {(plan.activeStep === 0 || !plan.activeStep) && (
                                <IntroductionTab
                                    onRef={el => (this.activeForm = el)}
                                    plan={plan}
                                    proposal={proposal}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    is_read_only={is_read_only}
                                    ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                    show_parcel={!Restoration && !Fee && !Conservation && !Easement}
                                    last_step={steps.indexOf("Reporting")}
                                    ActionMenu={
                                        <this.ActionMenu
                                            ormPropPrefix={ormPropPrefix}
                                            status_updates={status_updates}
                                            proposalDelegates={proposalDelegates}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            ormManagerRequestUpdate={ormManagerRequestUpdate}
                                            manager_requests={manager_requests}
                                            ormCompletionRequestDelete={ormCompletionRequestDelete}
                                            ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                            completion_requests={completion_requests}
                                            authState={authState}
                                            classes={classes}
                                            plan={plan}
                                            comments={comments}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            actionMenuClose={this.actionMenuClose}
                                            history={history}
                                        />
                                    }
                                />
                            )}
                            {plan.activeStep === 1 && (
                                <>
                                    <div className={classes.subStepContainer}>
                                        <Stepper nonLinear alternativeLabel activeStep={plan.activeSubStep || 0} connector={null} className={classes.subStep}>
                                            {getCollaboratorSteps().map((label, subIndex) => (
                                                <Step
                                                    key={label}
                                                    className={classNames(classes.pointer, subIndex === plan.activeSubStep && classes.activePoint)}>
                                                    <StepButton
                                                        className={classes.btnroot}
                                                        focusVisibleClassName={classes.pointerr}
                                                        onClick={() => this.handleStep(plan.activeStep, true, subIndex)}>
                                                        <StepLabel
                                                            classes={{
                                                                labelContainer: classes.lessMargin,
                                                                iconContainer: classes.hideIcon,
                                                                label: classes.whiteLabel
                                                            }}>
                                                            {label}
                                                        </StepLabel>
                                                    </StepButton>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </div>
                                    {plan.activeSubStep === 0 && (
                                        <CollaboratorsProjectManagerTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            manager_requests={manager_requests}
                                            plan={plan}
                                            ormParentUpdate={ormParentUpdate}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            helpmessages={helpmessages}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 1 && (
                                        <CollaboratorsOrganizationTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            plan={plan}
                                            ormParentUpdate={ormParentUpdate}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 2 && (
                                        <CollaboratorsPartnersTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            plan={plan}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                </>
                            )}
                            {plan.activeStep === 2 && (
                                <GeneralTab
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    plan={plan}
                                    ormParentUpdate={ormParentUpdate}
                                    ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    calcFundingTotal={this.calcFundingTotal}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    getDateTimeStamp={this.getDateTimeStamp}
                                    settings={settings}
                                    is_read_only={is_read_only}
                                    ActionMenu={
                                        <this.ActionMenu
                                            ormPropPrefix={ormPropPrefix}
                                            status_updates={status_updates}
                                            proposalDelegates={proposalDelegates}
                                            manager_requests={manager_requests}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            ormManagerRequestUpdate={ormManagerRequestUpdate}
                                            ormCompletionRequestDelete={ormCompletionRequestDelete}
                                            ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                            completion_requests={completion_requests}
                                            authState={authState}
                                            classes={classes}
                                            plan={plan}
                                            comments={comments}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            actionMenuClose={this.actionMenuClose}
                                            history={history}
                                        />
                                    }
                                />
                            )}
                            {plan.activeStep === 3 && (
                                <NarrativeTab
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    plan={plan}
                                    ormParentUpdate={ormParentUpdate}
                                    completion_requests={completion_requests}
                                    ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    getDateTimeStamp={this.getDateTimeStamp}
                                    settings={settings}
                                    is_read_only={is_read_only}
                                    ActionMenu={
                                        <this.ActionMenu
                                            ormPropPrefix={ormPropPrefix}
                                            status_updates={status_updates}
                                            proposalDelegates={proposalDelegates}
                                            manager_requests={manager_requests}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            ormManagerRequestUpdate={ormManagerRequestUpdate}
                                            ormCompletionRequestDelete={ormCompletionRequestDelete}
                                            ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                            completion_requests={completion_requests}
                                            authState={authState}
                                            classes={classes}
                                            plan={plan}
                                            comments={comments}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            actionMenuClose={this.actionMenuClose}
                                            history={history}
                                        />
                                    }
                                />
                            )}
                            {plan.activeStep === 4 && (
                                <ActivityTab
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    plan={plan}
                                    ormParentUpdate={ormParentUpdate}
                                    ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    getDateTimeStamp={this.getDateTimeStamp}
                                    settings={settings}
                                    viewMode={viewMode}
                                    is_read_only={is_read_only}
                                    ActionMenu={
                                        <this.ActionMenu
                                            ormPropPrefix={ormPropPrefix}
                                            status_updates={status_updates}
                                            proposalDelegates={proposalDelegates}
                                            manager_requests={manager_requests}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            ormManagerRequestUpdate={ormManagerRequestUpdate}
                                            ormCompletionRequestDelete={ormCompletionRequestDelete}
                                            ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                            completion_requests={completion_requests}
                                            authState={authState}
                                            classes={classes}
                                            plan={plan}
                                            comments={comments}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            actionMenuClose={this.actionMenuClose}
                                            history={history}
                                        />
                                    }
                                />
                            )}
                            {plan.activeStep === 5 && (
                                <>
                                    <div className={classes.subStepContainer}>
                                        <Stepper nonLinear alternativeLabel activeStep={plan.activeSubStep || 0} connector={null} className={classes.subStep}>
                                            {(viewMode && authState && authState.user.role === "Member" ? getMemberBugetSteps() : getBudgetSteps()).map(
                                                (label, subIndex) => {
                                                    if (subIndex === 3 && !Conservation && !Easement && !Fee) {
                                                        return (
                                                            <Tooltip title="You must select one of the options for the question 'Does your project include restoration or acquisition of land rights' under the General Step 3 above.">
                                                                <Step key={label} className={classes.disabledStep}>
                                                                    <StepButton className={classes.btnroot} focusVisibleClassName={classes.pointerr}>
                                                                        <StepLabel
                                                                            classes={{
                                                                                labelContainer: classes.lessMargin,
                                                                                iconContainer: classes.hideIcon,
                                                                                label: classes.whiteLabel
                                                                            }}>
                                                                            {label}
                                                                        </StepLabel>
                                                                    </StepButton>
                                                                </Step>
                                                            </Tooltip>
                                                        );
                                                    }
                                                    return (
                                                        <Step
                                                            key={label}
                                                            className={classNames(classes.pointer, subIndex === plan.activeSubStep && classes.activePoint)}>
                                                            <StepButton
                                                                className={classes.btnroot}
                                                                focusVisibleClassName={classes.pointerr}
                                                                onClick={() => this.handleStep(plan.activeStep, true, subIndex)}>
                                                                <StepLabel
                                                                    classes={{
                                                                        labelContainer: classes.lessMargin,
                                                                        iconContainer: classes.hideIcon,
                                                                        label: classes.whiteLabel
                                                                    }}>
                                                                    {label}
                                                                </StepLabel>
                                                            </StepButton>
                                                        </Step>
                                                    );
                                                }
                                            )}
                                        </Stepper>
                                    </div>
                                    {plan.activeSubStep === 0 && (
                                        <BudgetPersonnelTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            plan={plan}
                                            ormParentUpdate={ormParentUpdate}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 1 && (
                                        <BudgetContractsTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            plan={plan}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 2 && (
                                        <BudgetExpendituresTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            plan={plan}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 3 && (
                                        <BudgetAcquisitionTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            plan={plan}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 4 && (
                                        <BudgetTravelTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            plan={plan}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 5 && (
                                        <BudgetPrintingTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            plan={plan}
                                            ormParentUpdate={ormParentUpdate}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 6 && (
                                        <BudgetOtherTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            plan={plan}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    status_updates={status_updates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 7 && (
                                        <BudgetFundsTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            plan={plan}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 8 && (
                                        <BudgetSummaryTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            plan={plan}
                                            ormParentUpdate={ormParentUpdate}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            show_parcel={!Restoration && !Fee && !Conservation && !Easement}
                                            last_step={steps.indexOf("Reporting")}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                </>
                            )}
                            {plan.activeStep === 6 && (
                                <AttachmentsTab
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    plan={plan}
                                    ormParentUpdate={ormParentUpdate}
                                    ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    getDateTimeStamp={this.getDateTimeStamp}
                                    settings={settings}
                                    viewMode={viewMode}
                                    is_read_only={is_read_only}
                                    ActionMenu={
                                        <this.ActionMenu
                                            ormPropPrefix={ormPropPrefix}
                                            status_updates={status_updates}
                                            proposalDelegates={proposalDelegates}
                                            manager_requests={manager_requests}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            ormManagerRequestUpdate={ormManagerRequestUpdate}
                                            ormCompletionRequestDelete={ormCompletionRequestDelete}
                                            ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                            completion_requests={completion_requests}
                                            authState={authState}
                                            classes={classes}
                                            plan={plan}
                                            comments={comments}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            actionMenuClose={this.actionMenuClose}
                                            history={history}
                                        />
                                    }
                                />
                            )}
                            {plan.activeStep === 7 && (
                                <>
                                    <div className={classes.subStepContainer}>
                                        <Stepper nonLinear alternativeLabel activeStep={plan.activeSubStep || 0} connector={null} className={classes.subStep}>
                                            {getParcelSteps().map((label, subIndex) => {
                                                if (
                                                    (subIndex === 4 && !Restoration) ||
                                                    (subIndex === 2 && !Conservation) ||
                                                    (subIndex === 3 && !Easement) ||
                                                    (subIndex === 1 && !Fee)
                                                ) {
                                                    return (
                                                        <Tooltip title="You must select the appropriate answer for the question 'Does your project include restoration or acquisition of land rights' under the General Step">
                                                            <Step
                                                                key={label}
                                                                className={classNames(
                                                                    classes.disabledStep,
                                                                    subIndex === plan.activeSubStep && classes.activePoint
                                                                )}>
                                                                <StepButton
                                                                    disabled
                                                                    className={classes.btnroot}
                                                                    focusVisibleClassName={classes.pointerr}
                                                                    onClick={() => this.handleStep(plan.activeStep, true, subIndex)}>
                                                                    <StepLabel
                                                                        classes={{
                                                                            labelContainer: classes.lessMargin,
                                                                            iconContainer: classes.hideIcon,
                                                                            label: classes.whiteLabel
                                                                        }}>
                                                                        {label}
                                                                    </StepLabel>
                                                                </StepButton>
                                                            </Step>
                                                        </Tooltip>
                                                    );
                                                }
                                                return (
                                                    <Step
                                                        key={label}
                                                        className={classNames(classes.pointer, subIndex === plan.activeSubStep && classes.activePoint)}>
                                                        <StepButton
                                                            className={classes.btnroot}
                                                            focusVisibleClassName={classes.pointerr}
                                                            onClick={() => this.handleStep(plan.activeStep, true, subIndex)}>
                                                            <StepLabel
                                                                classes={{
                                                                    labelContainer: classes.lessMargin,
                                                                    iconContainer: classes.hideIcon,
                                                                    label: classes.whiteLabel
                                                                }}>
                                                                {label}
                                                            </StepLabel>
                                                        </StepButton>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </div>
                                    {plan.activeSubStep === 0 && (
                                        <AcquisitionParcelsTab
                                            onRef={el => (this.activeForm = el)}
                                            plan={plan}
                                            proposal={proposal}
                                            ormParentUpdate={ormParentUpdate}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            last_step={steps.indexOf("Reporting")}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 1 && (
                                        <AcquisitionFeeTab
                                            onRef={el => (this.activeForm = el)}
                                            plan={plan}
                                            proposal={proposal}
                                            ormParentUpdate={ormParentUpdate}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 2 && (
                                        <AcquisitionConservationTab
                                            onRef={el => (this.activeForm = el)}
                                            plan={plan}
                                            proposal={proposal}
                                            ormParentUpdate={ormParentUpdate}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 3 && (
                                        <AcquisitionOtherTab
                                            onRef={el => (this.activeForm = el)}
                                            plan={plan}
                                            proposal={proposal}
                                            ormParentUpdate={ormParentUpdate}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 4 && (
                                        <AcquisitionRestorationTab
                                            onRef={el => (this.activeForm = el)}
                                            plan={plan}
                                            proposal={proposal}
                                            ormParentUpdate={ormParentUpdate}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                </>
                            )}
                            {plan.activeStep === 8 && (
                                <Dissemination
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    plan={plan}
                                    ormParentUpdate={ormParentUpdate}
                                    ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    getDateTimeStamp={this.getDateTimeStamp}
                                    settings={settings}
                                    viewMode={viewMode}
                                    is_read_only={is_read_only}
                                    ActionMenu={
                                        <this.ActionMenu
                                            ormPropPrefix={ormPropPrefix}
                                            status_updates={status_updates}
                                            proposalDelegates={proposalDelegates}
                                            manager_requests={manager_requests}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            ormManagerRequestUpdate={ormManagerRequestUpdate}
                                            ormCompletionRequestDelete={ormCompletionRequestDelete}
                                            ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                            completion_requests={completion_requests}
                                            authState={authState}
                                            classes={classes}
                                            plan={plan}
                                            comments={comments}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            actionMenuClose={this.actionMenuClose}
                                            history={history}
                                        />
                                    }
                                />
                            )}
                            {reviewScoring &&
                            plan.activeStep === steps.indexOf("Comments and Revisions") && ( // admin only
                                    <FinalCommentsTab
                                        onRef={el => (this.activeForm = el)}
                                        proposal={proposal}
                                        plan={plan}
                                        ormParentUpdate={ormParentUpdate}
                                        ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                        appContainer={this.appContainer}
                                        handleNext={this.handleNext}
                                        handlePrev={this.handlePrev}
                                        handleUnsavedFields={this.handleUnsavedFields}
                                        getDateTimeStamp={this.getDateTimeStamp}
                                        settings={settings}
                                        is_read_only={is_read_only}
                                        getSteps={steps}
                                        ActionMenu={
                                            <this.ActionMenu
                                                ormPropPrefix={ormPropPrefix}
                                                status_updates={status_updates}
                                                proposalDelegates={proposalDelegates}
                                                manager_requests={manager_requests}
                                                ormManagerRequestDelete={ormManagerRequestDelete}
                                                ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                completion_requests={completion_requests}
                                                authState={authState}
                                                classes={classes}
                                                plan={plan}
                                                comments={comments}
                                                proposal={proposal}
                                                actionMenuOpen={this.actionMenuOpen}
                                                anchorEl={this.state.anchorEl}
                                                actionMenuClose={this.actionMenuClose}
                                                history={history}
                                            />
                                        }
                                    />
                                )}
                            {user && plan.activeStep === steps.indexOf("Comments and Revisions") && (
                                <CommentsRevisionsTab
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    plan={plan}
                                    ormParentUpdate={ormParentUpdate}
                                    ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    getDateTimeStamp={this.getDateTimeStamp}
                                    settings={settings}
                                    is_read_only={is_read_only}
                                    getSteps={steps}
                                    ActionMenu={
                                        <this.ActionMenu
                                            ormPropPrefix={ormPropPrefix}
                                            status_updates={status_updates}
                                            proposalDelegates={proposalDelegates}
                                            manager_requests={manager_requests}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            ormManagerRequestUpdate={ormManagerRequestUpdate}
                                            ormCompletionRequestDelete={ormCompletionRequestDelete}
                                            ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                            completion_requests={completion_requests}
                                            authState={authState}
                                            classes={classes}
                                            plan={plan}
                                            comments={comments}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            actionMenuClose={this.actionMenuClose}
                                            history={history}
                                        />
                                    }
                                />
                            )}
                            {user && plan.activeStep === steps.indexOf("Review / Submit") && (
                                <ReviewTab
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    plan={plan}
                                    handleStep={this.handleStep}
                                    keepState={this.keepState}
                                    ormParentUpdate={ormParentUpdate}
                                    ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    getDateTimeStamp={this.getDateTimeStamp}
                                    settings={settings}
                                    is_read_only={is_read_only}
                                    show_parcel={!Restoration && !Fee && !Conservation && !Easement}
                                    ActionMenu={
                                        <this.ActionMenu
                                            ormPropPrefix={ormPropPrefix}
                                            status_updates={status_updates}
                                            proposalDelegates={proposalDelegates}
                                            manager_requests={manager_requests}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            ormManagerRequestUpdate={ormManagerRequestUpdate}
                                            ormCompletionRequestDelete={ormCompletionRequestDelete}
                                            ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                            completion_requests={completion_requests}
                                            authState={authState}
                                            classes={classes}
                                            plan={plan}
                                            comments={comments}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            actionMenuClose={this.actionMenuClose}
                                            history={history}
                                        />
                                    }
                                />
                            )}
                            {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && plan.activeStep === steps.indexOf("Reporting") && (
                                <>
                                    <div className={classes.subStepContainer}>
                                        <Stepper nonLinear alternativeLabel activeStep={plan.activeSubStep || 0} connector={null} className={classes.subStep}>
                                            {getReportingSteps(proposal.status).map((label, subIndex) => {
                                                return (
                                                    <Step
                                                        key={label}
                                                        className={classNames(classes.pointer, subIndex === plan.activeSubStep && classes.activePoint)}>
                                                        <StepButton
                                                            className={classes.btnroot}
                                                            focusVisibleClassName={classes.pointerr}
                                                            onClick={() => this.handleStep(plan.activeStep, true, subIndex)}>
                                                            <StepLabel
                                                                classes={{
                                                                    labelContainer: classes.lessMargin,
                                                                    iconContainer: classes.hideIcon,
                                                                    label: classes.whiteLabel
                                                                }}>
                                                                {label}
                                                            </StepLabel>
                                                        </StepButton>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </div>
                                    {plan.activeSubStep === 0 && (
                                        <ReportingTab
                                            proposal={proposal}
                                            plan={plan}
                                            ormParentUpdate={ormParentUpdate}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            is_read_only={is_read_only}
                                            show_parcel={!Restoration && !Fee && !Conservation && !Easement}
                                            last_step={steps.indexOf("Review / Submit")}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 1 && (
                                        <AmendmentsTab
                                            plan={plan}
                                            appContainer={this.appContainer}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            is_read_only={is_read_only}
                                            manager_requests={manager_requests}
                                            ormManagerRequestUpdate={ormManagerRequestUpdate}
                                            completion_requests={completion_requests}
                                            ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                    {plan.activeSubStep === 2 && (
                                        <FinalAbstractTab
                                            plan={plan}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            appContainer={this.appContainer}
                                            ormParentUpdate={ormParentUpdate}
                                            ormParentUpdateLocalOnly={ormParentUpdateLocalOnly}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            proposal={proposal}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    ormPropPrefix={ormPropPrefix}
                                                    status_updates={status_updates}
                                                    proposalDelegates={proposalDelegates}
                                                    manager_requests={manager_requests}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    ormManagerRequestUpdate={ormManagerRequestUpdate}
                                                    ormCompletionRequestDelete={ormCompletionRequestDelete}
                                                    ormCompletionRequestUpdate={ormCompletionRequestUpdate}
                                                    completion_requests={completion_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    plan={plan}
                                                    comments={comments}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    actionMenuClose={this.actionMenuClose}
                                                    history={history}
                                                />
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.finalUpdateDue}
                    onClose={() => {
                        this.setState({ finalUpdateDue: false });
                    }}
                    disableBackdropClick={true}>
                    <Form
                        validateOnSubmit={true}
                        validateError={this.validateDueDate}
                        defaultValues={{due_date: new Date(new Date().getTime()+(45*24*60*60*1000))}}
                        dontValidateOnMount={true}
                        onSubmit={values => this.abstractDueDate(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                                    <Typography variant="h3">Add Abstract Due Date</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            this.setState({ finalUpdateDue: false });
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <Typography>You have approved a final status update which has triggered the final abstract process. By default the abstract will be due 7 days from today, but you may overwrite this below.</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker field="due_date" label="Select Abstract Due Date" fullWidth />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ marginRight: 5 }} onClick={() => this.setState({ finalUpdateDue: null })}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Set Abstract Due Date
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <Dialog
                    open={this.state.revisionOpen}
                    onClose={() => {
                        this.setState({ revisionOpen: false });
                    }}
                    disableBackdropClick={true}>
                    <Form
                        validateOnSubmit={true}
                        validateError={this.validateDueDate}
                        defaultValues={null}
                        dontValidateOnMount={true}
                        onSubmit={values => this.dueDate(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                                    <Typography variant="h3">Add Revision Due Date</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            this.setState({ revisionOpen: false });
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <DatePicker field="due_date" label="Select Due Date" fullWidth />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ marginRight: 5 }} onClick={() => this.setState({ revisionOpen: null })}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Set Due Date and Send Work Plan Back to Project Manager for Revisions
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <Dialog
                    open={denyProjectManagerState}
                    onClose={() => {
                        this.setState({ denyProjectManagerState: false });
                    }}
                    disableBackdropClick={true}>
                    <Form
                        validateOnSubmit={true}
                        validateError={this.validateDeny}
                        defaultValues={null}
                        dontValidateOnMount={true}
                        onSubmit={values => {
                            this.denyProjectManager(values);
                        }}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                                    <Typography variant="h3">Deny Project Manager Request</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            this.setState({ denyProjectManagerState: false });
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <TextField field="decline_reason" label="Enter Reason Why" fullWidth />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ marginRight: 5 }} onClick={() => this.setState({ denyProjectManagerState: false })}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Deny Project Manager Request
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <Dialog
                    open={denyCompletionManagerState}
                    onClose={() => {
                        this.setState({ denyCompletionManagerState: false });
                    }}
                    disableBackdropClick={true}>
                    <Form
                        validateOnSubmit={true}
                        validateError={this.validateDeny}
                        defaultValues={null}
                        dontValidateOnMount={true}
                        onSubmit={values => {
                            this.denyCompletionDate(values);
                        }}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                                    <Typography variant="h3">Deny Completion Date Request</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            this.setState({ denyCompletionManagerState: false });
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <TextField field="decline_reason" label="Enter Reason Why" fullWidth />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ marginRight: 5 }} onClick={() => this.setState({ denyCompletionManagerState: false })}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Deny Completion Date Request
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <Dialog
                    open={statusUpdateChangeDate}
                    onClose={() => {
                        this.setState({ statusUpdateChangeDate: false });
                    }}
                    disableBackdropClick={true}>
                    <Form
                        validateOnSubmit={true}
                        validateError={this.validateDueDate}
                        defaultValues={{send_back: "Both"}}
                        dontValidateOnMount={true}
                        onSubmit={values => {
                            if (denyContents === "both") {
                                if (values.send_back === "Both" || values.send_back === "Status Update")
                                    this.denyStatusUpdate(values);
                                if (values.send_back === "Both" || values.send_back === "Amendment")
                                    this.denyAmendment(values);
                            }
                            else if (denyContents === "status") {
                                this.denyStatusUpdate(values);
                            }
                            else {
                                this.denyAmendment(values);
                            }
                        }}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                                    <Typography variant="h3">Return Revisions</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            this.setState({ statusUpdateChangeDate: false });
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        {denyContents === "both" && (
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    field="send_back"
                                                    name="send_back"
                                                    fullWidth
                                                    options={[
                                                        {
                                                            label: "Both",
                                                            value: "Both"
                                                        },
                                                        {
                                                            label: "Status Update",
                                                            value: "Status Update"
                                                        },
                                                        {
                                                            label: "Amendment",
                                                            value: "Amendment"
                                                        }
                                                    ]}
                                                    label="Send which back for revisions"
                                                    alignment={true}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <DatePicker field="due_date" label="Select Due Date" fullWidth />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ marginRight: 5 }} onClick={() => this.setState({ statusUpdateChangeDate: false })}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        {denyContents === "both" && (
                                            <>Send Selected Items Back For Revisions</>
                                        )}
                                        {denyContents === "status" && (
                                            <>Send Update Back For Revisions</>
                                        )}
                                        {denyContents === "amendment" && (
                                            <>Send Amendment Back For Revisions</>
                                        )}
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <Dialog
                    open={abstractChangeDate}
                    onClose={() => {
                        this.setState({ abstractChangeDate: false });
                    }}
                    disableBackdropClick={true}>
                    <Form
                        validateOnSubmit={true}
                        validateError={this.validateDueDate}
                        defaultValues={{send_back: "Both"}}
                        dontValidateOnMount={true}
                        onSubmit={values => {
                            this.denyFinalAbstract(values);
                        }}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                                    <Typography variant="h3">Return Revisions</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            this.setState({ abstractChangeDate: false });
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <DatePicker field="due_date" label="Select Final Abstract Due Date" fullWidth />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ marginRight: 5 }} onClick={() => this.setState({ abstractChangeDate: false })}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Send Final Abstract Back For Revisions
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={this.state.reqeuestWidrawal}
                    onClose={() => {
                        this.setState({ reqeuestWidrawal: false });
                    }}
                    disableBackdropClick={true}>
                    <Form
                        validateOnSubmit={true}
                        validateError={this.validateWithdrawal}
                        defaultValues={proposal}
                        dontValidateOnMount={true}
                        onSubmit={values => this.requestWithDrawal(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                                    <Typography variant="h3">Request Withdrawal</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            this.setState({ reqeuestWidrawal: false });
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <TextField field="withdrawal_reason" label="Reason" fullWidth multiline />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ marginRight: 5 }} onClick={() => this.setState({ reqeuestWidrawal: null })}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Send Request
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <Dialog
                    open={this.state.adminSaving}
                    disableBackdropClick={true}>
                    <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                        <Typography variant="h3">Saving...</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography>The project is being saved and the report is being generated. Please wait.</Typography>
                        <Grid container spacing={16} justify="center" alignItems="center">
                            <Grid item>
                                <CircularProgress size={40} aria-label="Loading Icon" thickness={1.5} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.addStaffComment}
                    onClose={() => {
                        this.setState({ addStaffComment: false });
                    }}
                    disableBackdropClick={true}>
                    <Form
                        validateOnSubmit={true}
                        validateError={this.validateComment}
                        defaultValues={{
                            comment_for: "Applicant",
                            step: plan.activeStep
                        }}
                        dontValidateOnMount={true}
                        onSubmit={values => this.addStaffComment(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                                    <Typography variant="h3">Add Staff Comment</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            this.setState({ addStaffComment: false });
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <Typography>Step: {steps[plan.activeStep]}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                field="comment_for"
                                                name="comment_for"
                                                fullWidth
                                                options={[
                                                    {
                                                        label: "Project Manager",
                                                        value: "Applicant"
                                                    },
                                                    {
                                                        label: "Member",
                                                        value: "Member"
                                                    },
                                                    {
                                                        label: "Staff",
                                                        value: "Staff"
                                                    }
                                                ]}
                                                label="Comment For"
                                                alignment={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="comment" label="Comment or requirement not met detail" fullWidth multiline />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ marginRight: 5 }} onClick={() => this.setState({ addStaffComment: null })}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Add Comment
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <DelegateDialog
                    open={delegateDialog}
                    proposal={proposal}
                    bearer={authState}
                    ormProposalDelegateReload={ormProposalDelegateReload}
                    returnDialog={() => this.setState({ delegateDialog: false })}
                    classes={classes}
                    formkey={formkey}
                />
                <DeleteDelegateDialog
                    open={deleteDelegateDialog}
                    proposal={proposal}
                    delegates={proposalDelegates}
                    ormDelete={ormProposalDelegateDelete}
                    returnDialog={() => this.setState({ deleteDelegateDialog: false })}
                    classes={classes}
                    formkey={formkey}
                />
                <Dialog
                    open={this.state.amendmentStartOpen}
                    onClose={() => {
                        this.setState({ amendmentStartOpen: false });
                    }}
                    disableBackdropClick={true}>
                    <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                        <Typography variant="h3">Initiate Amendment Request</Typography>
                        <IconButton
                            aria-label="Close Dialog"
                            className={classes.closeButton}
                            onClick={() => {
                                this.setState({ amendmentStartOpen: false });
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography>
                                    Warning!
                                    <br />
                                    <br />
                                    You are requesting to make changes to the work plan, which serves as the agreement between you and the LCCMR 
                                    of the work that will be performed with funding from the ENRTF.
                                    <br />
                                    <br />
                                    Clicking the button below will put this page of your work plan in "editing mode." You will be asked on the Amendments page
                                    to explain each edit you are proposing. Significant edits, either in scope or quantity, may result in delayed response from
                                    LCCMR staff and possible elevation to the full commission.
                                    <br />
                                    <br />
                                    Amendment requests are not reviewed by LCCMR staff until you submit the amended work plan on the Review/Submit page. Amendments
                                    are not approved and should not be implemented until or unless explicitly approved in this work plan by LCCMR staff.
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ marginRight: 8 }} onClick={() => this.setState({ amendmentStartOpen: false })}>
                            Cancel Amendment Request
                        </Button>
                        <Button color="primary" variant="contained" onClick={() => this.requestAmendment()}>
                            Enter Work Plan Editing Mode
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.amendmentEndOpen}
                    onClose={() => {
                        this.setState({ amendmentEndOpen: false });
                    }}
                    disableBackdropClick={true}>
                    <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                        <Typography variant="h3">Withdraw Amendment Request</Typography>
                        <IconButton
                            aria-label="Close Dialog"
                            className={classes.closeButton}
                            onClick={() => {
                                this.setState({ amendmentEndOpen: false });
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography>
                                    Warning!
                                    <br />
                                    <br />
                                    You are about to delete this amendment request. This will delete all changes made and will revert the work plan back to the
                                    most recently approved version. <b>Are you sure you want to delete this amendment request?</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ marginRight: 8 }} onClick={() => this.setState({ amendmentEndOpen: false })}>
                            No, Continue Working
                        </Button>
                        <Button color="primary" variant="contained" onClick={() => this.revertAmendment()}>
                            Yes, Delete Amendment Request
                        </Button>
                    </DialogActions>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        this.acceptPlan(ormPropPrefix);
                    }}
                    cancelAction={() => this.setState({ acceptPlanDialog: false })}
                    open={acceptPlanDialog}
                    title={`Accept ${ormPropPrefix} Work Plan`}
                    confirmText={`Accept ${ormPropPrefix} Work Plan`}
                    cancelText="Cancel"
                    text={`Are you sure you wish to accept the ${ormPropPrefix.toLowerCase()} work plan? This will set status to ${ormPropPrefix} Work Plan Accepted.`}
                />
            </AppContainer>
        );
    }
}

class DeleteDelegateDialog extends Component {
    deleteDelegation(values) {
        const { ormDelete, returnDialog } = this.props;
        ormDelete(values.id);
        returnDialog();
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            id: isRequired(values.id)
        };
        return valObj;
    };

    render() {
        const { classes, delegates, open, returnDialog, formkey, proposal } = this.props;
        const filteredDelegates = delegates.filter(x => x.proposal === proposal.id);

        return (
            <Dialog role="main" aria-label="Delete Delegate Access" open={open} classes={{ paper: classes.nooverflow }} maxWidth="sm" fullWidth>
                <DialogTitle className={classes.titleContainer}>
                    <ErrorOutline className={classes.supportIcon} />
                    <IconButton
                        onClick={() => {
                            returnDialog();
                        }}
                        className={classes.closeIcon}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h1" className={classes.questionTitle} gutterBottom>
                        Delete Delegate Access
                    </Typography>
                    <DialogContentText>
                        <Typography>
                            <b>Removing Delegate Access means the user can no longer edit this proposal!</b>
                        </Typography>
                    </DialogContentText>
                    <Form
                        dontValidateOnMount={true}
                        key={formkey}
                        validateOnSubmit={true}
                        validateError={this.errorValidator}
                        onSubmit={values => this.deleteDelegation(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <Select
                                            required
                                            label="Select User"
                                            field="id"
                                            options={filteredDelegates.map(x => ({ value: x.id, label: x.email }))}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            onClick={() => {
                                                this.setState({ message: null, loading: false });
                                                returnDialog();
                                            }}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth type="submit" variant="contained" color="primary">
                                            Delete
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                </DialogContent>
            </Dialog>
        );
    }
}

class DelegateDialog extends Component {
    state = {
        loading: false,
        message: null
    };

    addDelegation(values) {
        const { ormProposalDelegateReload, proposal, returnDialog, bearer } = this.props;
        this.setState({ loading: true, message: null });

        fetch("/api/db/proposaldelegates/delegate", {
            method: "POST",
            headers: {
                Authorization: "Token " + bearer.user.auth_token,
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: JSON.stringify({
                email: values.email,
                proposal_id: proposal.id
            })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    this.setState({ message: data.message, loading: false });
                } else {
                    ormProposalDelegateReload();
                    this.setState({
                        message: null,
                        loading: false
                    });
                    returnDialog();
                }
            });
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            email: isRequired(values.email),
            agree: isRequired(values.agree)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, formkey, proposal } = this.props;
        const { loading, message } = this.state;

        return (
            <Dialog role="main" aria-label="Add New User Permission" open={open}>
                <Toolbar>
                    <Typography variant="h1" className={classes.flex}>
                        Add New User Permission
                    </Typography>
                    <IconButton
                        aria-label="Close Dialog"
                        onClick={() => {
                            this.setState({ message: null, loading: false });
                            returnDialog();
                        }}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            key={formkey}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            onSubmit={values => this.addDelegation(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <TextField field="email" label="Enter Email of User to Delegate Permission to" fullWidth />
                                            <Checkbox
                                                style={{ marginTop: 16 }}
                                                field="agree"
                                                label={
                                                    'I agree to give this user full access to edit my proposal ["' +
                                                    proposal.name +
                                                    '"], but understand they can not submit my proposal. As project manager, I retain responsibility for the accuracy of all information.'
                                                }
                                            />
                                        </Grid>
                                        {loading && (
                                            <div className={classes.progress}>
                                                <CircularProgress aria-label="Loading Icon" size={24} />
                                            </div>
                                        )}
                                        {!loading && (
                                            <>
                                                <Grid item xs={6}>
                                                    <Button
                                                        fullWidth
                                                        onClick={() => {
                                                            this.setState({ message: null, loading: false });
                                                            returnDialog();
                                                        }}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button fullWidth type="submit" variant="contained" color="primary">
                                                        Save
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} style={{ color: "#be0000" }}>
                                                    {message}
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

List = connect(
    (state, ownProps) => ({
        synced: state.sync,
        comments: getComments(state, ownProps),
        proposal: getProposal(state, ownProps),
        plan: getPlan(state, ownProps),
        funding: getFundingArrays(state, ownProps),
        proposalDelegates: getDelegations(state, ownProps),
        authState: state.auth,
        helpmessages: getHelpMessages(state),
        status_updates: getStatusUpdates(state, ownProps),
        status_update_activities: getStatusUpdateActivities(state, ownProps),
        rfps: getRFPs(state, ownProps),
        manager_requests: getManagerRequests(state, ownProps),
        completion_requests: getCompletionRequests(state, ownProps),
        amendments: getAmendments(state, ownProps)
    }),
    {
        ...WorkPlanReview.actions,
        ...WorkPlanComment.actions,
        ...Proposal.actions,
        ...DraftWorkPlan.actions,
        ...DraftWorkPlanView.actions,
        ...ProposalView.actions,
        ...ProposalDelegate.actions,
        ...DraftProposalCollaborator.actions,
        ...DraftProposalActivity.actions,
        ...DraftProposalMilestone.actions,
        ...DraftProposalPastProject.actions,
        ...DraftProposalPersonnel.actions,
        ...DraftProposalContract.actions,
        ...DraftProposalExpenditure.actions,
        ...DraftProposalAcquisition.actions,
        ...DraftProposalTravel.actions,
        ...DraftProposalPrinting.actions,
        ...DraftProposalExpense.actions,
        ...DraftProposalNonENRTFFund.actions,
        ...DraftProposalAttachment.actions,
        ...DraftProposalParcel.actions,
        ...FinalWorkPlan.actions,
        ...FinalWorkPlanView.actions,
        ...FinalProposalCollaborator.actions,
        ...FinalProposalActivity.actions,
        ...FinalProposalMilestone.actions,
        ...FinalProposalPastProject.actions,
        ...FinalProposalPersonnel.actions,
        ...FinalProposalContract.actions,
        ...FinalProposalExpenditure.actions,
        ...FinalProposalAcquisition.actions,
        ...FinalProposalTravel.actions,
        ...FinalProposalPrinting.actions,
        ...FinalProposalExpense.actions,
        ...FinalProposalNonENRTFFund.actions,
        ...FinalProposalAttachment.actions,
        ...FinalProposalParcel.actions,
        ...StatusUpdate.actions,
        ...StatusUpdateActivity.actions,
        ...AmendmentLog.actions,
        ...ManagerRequest.actions,
        ...CompletionRequest.actions
    }
)(List);
export default withStyles(styles)(withRouter(List));
